import "../App.scss";
import "./Navigation.scss";

import React from 'react';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../assets/logo-main.svg";
import { LinkContainer } from 'react-router-bootstrap'

export default function Navigation() {
    return (
        <Navbar collapseOnSelect fixed="top" className="p-0 bg-secondary border border-primary" expand="lg">
            <Container fluid className="p-0">
                    <Navbar.Brand href="/"className="ml-2 ml-lg-4" aria-controls="basic-navbar-nav">
                        <img
                            src={logo}
                            className="d-inline-block align-top px-2 main-logo"
                            alt="Pitchichi I Trouver la meilleure agence de communication à Paris et partout en France"
                        />
                    </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav
                        className="ml-auto font-nhaas55 font-size-nav"
                    >
                        <Nav.Item className="px-xl-4">
                            <LinkContainer to="/histoire-pitchichi-thomas-sabatier">
                                <Nav.Link className="header">À propos</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item className="pr-xl-4">
                            <LinkContainer to="/offres-recherche-agences-communication">
                                <Nav.Link className="header">Offres</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item className="pr-xl-4">
                            <LinkContainer to="/guide-meilleures-agences-pub-com">
                                <Nav.Link className="header">Guide des agences</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item className="pr-xl-5">
                            <LinkContainer to="/contact-pitchichi">
                                <Nav.Link className="header">Contact</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                        <Nav.Item className="px-xl-5 bg-yellow border-left border-primary">
                            <LinkContainer to="/competition-agence-communication">
                                <Nav.Link className="header">Cabinet de conseil</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}