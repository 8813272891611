
import "../../App.scss";
import "./Agencies.scss";
import { fadeIn } from 'react-animations'
import styled, { keyframes } from 'styled-components'
import React, { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { AGENCIES_URL } from "../../util/Constant";
import nextId from 'react-id-generator';
import { Helmet } from "react-helmet";

import { LinkContainer } from 'react-router-bootstrap'
import card1 from "./../../assets/illustrations/main/card1.png.webp";

import Container from "react-bootstrap/Container";
import CloseButton from "react-bootstrap/CloseButton";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import campaign from "./../../assets/illustrations/campaign.svg";
const FadeIn = styled.div`animation: 2s ${keyframes`${fadeIn}`}`;
export default function Agencies() {
    const [agencies, setAgencies] = useState()
    const [searchName, setSearchName] = useState('')
    const [searchLocation, setSearchLocation] = useState('')
    const [nextPageUrl, setNextPageUrl] = useState('')
    const [previousPageUrl, setPreviousPageUrl] = useState('')
    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' })

    const getAgencies = () => {
        axios.get(AGENCIES_URL)
            .then((response) => {
                setAgencies(response.data)
                setNextPageUrl(response.data.next)
            })
            .catch(error => console.error(error))
    }

    const getAgenciesNext = (url) => {
        axios.get(url)
            .then((response) => {
                executeScroll()
                setAgencies(response.data)
                setNextPageUrl(response.data.next)
                setPreviousPageUrl(response.data.previous)

            })
            .catch(error => console.error(error))
    }

    const getAgenciesPrevious = (url) => {
        axios.get(url)
            .then((response) => {
                executeScroll()
                setAgencies(response.data)
                setNextPageUrl(response.data.next)
                setPreviousPageUrl(response.data.previous)

            })
            .catch(error => console.error(error))
    }

    const launchSearch = () => {
        const params = {
            name: searchName, location: searchLocation
        }
        for (const key of Object.keys(params)) {
            if (params[key] === "") {
                delete params[key];
            }
        }
        axios.get(AGENCIES_URL, { params: params })
            .then((response) => {
                setAgencies(response.data)
                setNextPageUrl(response.data.next)
            })
            .catch(error => console.error(error))
    }


    useEffect(() => {
        getAgencies();
    }, []);

    return (
        <>
            <Helmet>
                <title>Pitchichi | Liste des meilleures agences de communication (branding, digital, publicité, social media, luxe) - France
                </title>
                <meta name="description" content="Le guide de toutes les meilleures agences de communication à Paris et dans toute la France." />
                <link rel="pitchichi" href="https://www.pitchichi.com/liste-meilleures-agences-communication-pub-branding-marque-France" />
            </Helmet>

            <Container fluid className="agencies bg-blue fade-in-fast text-primary d-flex flex-column justify-content-center wmax-2160">

                <Row className="section-1 align-items-center flex-column flex-xl-row">
                    <Col className="d-flex justify-content-center justify-content-xl-end pr-0 pr-xl-5">
                        <Image src={campaign} fluid className="agencies-section-1-img pr-0 pr-xl-5 h-100" />
                    </Col>
                    <Col className="mt-3 mt-xl-0 p-0">
                        <Row>
                            <Col className="font-size-90 line-height-sm text-center text-xl-left text-uppercase">
                                <div className="font-recoletalight">Agences</div>
                                <div className="d-flex justify-content-center"><span className="font-recoletalight">de&nbsp;</span><span className="font-nhaas65"> communication</span></div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="font-size-35 text-center text-xl-left font-nhaas45 line-height-xl">Listing des principales agences françaises</div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="py-4">
                        <div className="font-size-35 text-center font-recoletasemibold line-height-xl">Rechercher la perle rare</div>
                    </Col>
                </Row>
                <form onSubmit={launchSearch}>
                    <Row className="align-items-center flex-column px-2">
                        <Col md={8} className="bg-white rounded-30 p-2 p-md-4">
                            <Row>

                                <Col xs={6} sm={4}>
                                    <div className="search-field">
                                        <Form.Group className="" controlId="formAgenciesName">
                                            <Form.Label className="font-size-18">PAR NOM</Form.Label>
                                            {searchName && <CloseButton onClick={() => setSearchName('')} />}
                                            <Form.Control type="text" onChange={(e) => setSearchName(e.target.value)} value={searchName} autoComplete="off" />

                                        </Form.Group>
                                    </div>
                                </Col>
                                <Col xs={6} sm={4}>
                                    <div className="search-field">
                                        <Form.Group className="" controlId="formAgenciesLocation">
                                            <Form.Label className="font-size-18">PAR LOCALITÉ</Form.Label>
                                            {searchLocation && <CloseButton onClick={() => setSearchLocation('')} />}

                                            <Form.Control type="text" onChange={(e) => setSearchLocation(e.target.value)} value={searchLocation} autoComplete="off" />
                                        </Form.Group>
                                    </div>
                                </Col>
                                <Col xs={12} sm={4} className="d-flex align-items-center justify-content-center mt-4 mt-md-0">
                                    <Button ref={myRef} className="btn-pitchichi-primary" onClick={launchSearch}>Lancer la recherche</Button>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </form>

            </Container>
            <div className="bg-secondary">
                <Container fluid className="agencies-section-2 bg-secondary justify-content-center d-flex flex-column fade-in-slow wmax-2160">
                    <Row className="text-center line-height-1">
                        <Col className="font-size-35 font-recoletasemibold align-items-center justify-content-center d-flex flex-column text-primary mb-5">
                            <div className="">
                                <span className="">
                                    Liste des agences
                                </span>
                            </div>

                        </Col>
                    </Row>

                    <Row xs={2} md={4} className="fade-in-slow text-primary agencies-card-container">
                        {agencies && agencies?.results?.map((agency) => (

                            <div className="clickable" key={nextId()}>
                                <LinkContainer to={`/${agency?.slug}`}>

                                    <Card className="card-agency">
                                        <Card.Header style={{ background: agency?.team_picture?.file !== undefined ? `url(${agency?.team_picture?.file})` : "#d0e4fc" }}>
                                            <div className="card-agency-logo" style={{ backgroundImage: `url(${agency?.logo?.file})` }}></div>
                                        </Card.Header>
                                        <Card.Body className="line-height-1 pt-4 pl-3 pb-3 pt-md-5">
                                            <div className="font-size-35 font-NeueHaasDisplayMedium">{agency?.name}</div>
                                            <div className="font-size-30 font-nhaas45 py-1">{agency?.city}
                                                {agency?.secondary_cities?.map((sc) => (
                                                    <span key={nextId()}> - {sc}</span>
                                                ))}
                                            </div>
                                            {agency?.holding_name
                                                ? <div className="font-size-20 font-NeueHaasDisplayMedium text-uppercase">{agency?.holding_name}</div>
                                                : <div className="font-size-20 font-NeueHaasDisplayMedium text-uppercase">Indépendant</div>
                                            }
                                            <div className="font-size-20 font-NeueHaasDisplayMedium">{agency?.effectif_total} pers.</div>
                                        </Card.Body>
                                    </Card>
                                </LinkContainer>
                            </div>
                        ))}


                    </Row>
                    {agencies && <FadeIn><Row className="justify-content-center  mb-4">

                        <Button className="btn-pitchichi-previous m-3" disabled={!previousPageUrl} onClick={() => getAgenciesPrevious(previousPageUrl)}>Page précédente</Button>

                        <Button className="btn-pitchichi-next m-3" disabled={!nextPageUrl} onClick={() => getAgenciesNext(nextPageUrl)}>Page suivante</Button>
                    </Row></FadeIn>}
                </Container>
            </div>

            <Container fluid className="agencies guide-section-choice bg-secondary justify-content-center d-flex flex-column fade-in-slow ">
                <Row className="text-center line-height-1 ">
                    <Col className="align-items-center justify-content-center d-flex flex-column text-primary">
                        <div className="guide-section-logo">
                            <img src={card1} alt=""></img>
                        </div>
                        <div className="mt-md-5 mt-3">
                            <span className="font-size-35 font-nhaas45">
                                Difficile de faire son choix ?
                            </span>
                        </div>
                        <div className="mb-md-5 mb-3 d-none d-sm-block">
                            <span className="font-size-35 font-nhaas45">
                                Pitchichi vous propose sa sélection d’agences pour votre projet.
                            </span>
                        </div>
                        <div className="mb-md-5 mb-3 d-block d-sm-none">
                            <div className="font-size-35 font-nhaas45">
                                Pitchichi vous propose sa sélection d’agences
                            </div>
                            <div className="font-size-35 font-nhaas45">
                                pour votre projet.
                            </div>
                        </div>

                        <LinkContainer to="/wizard">
                            <Button variant="yellow" className="btn-pitchichi-primary">Recevoir ma sélection gratuite</Button>
                        </LinkContainer>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
