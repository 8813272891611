import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Legal() {
    return (
        <>
            <Container className="text-primary pt-5">
                <Row className="text-primary pt-5">
                    <Col className="text-primary pt-5">
                        <div className="font-size-35 font-recoletamedium">Informations légales</div>
                        <br></br>
                        <span className="font-recoletasemibold"></span>
                        <div className="font-nhaas45 font-size-25">
                            Informations fournies en vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique :
                            Pitchichi est une marque commerciale de la société PASSING-SHOT, SAS au capital de 30 612,20 €
                            10 D rue Alexandre Coutureau 92210 Saint-Cloud
                        </div>
                        <br></br>
                        <div className="font-size-35 font-recoletamedium">Editeur</div>
                        <div className="font-nhaas45 font-size-25">
                            Le site de Pitchichi est géré par Passing-Shot
                        </div>
                        <div className="font-nhaas45 font-size-25">
                        10 D rue Alexandre Coutureau, 92210 Saint-Cloud
                        </div>
                        <div className="font-nhaas45 font-size-25">
                            RCS Nanterre
                        </div>
                        <div className="font-nhaas45 font-size-25">
                            SIRET : 840 030 100 00027
                        </div>
                        <div className="font-nhaas45 font-size-25">
                            Tél : +33.9.52.63.24.88
                        </div>
                        <div className="font-nhaas45 font-size-25">
                            Mail : contact@pitchichi.com
                        </div>
                        <br></br>
                        <div className="font-size-35 font-recoletamedium">Responsable de publication</div>
                        <div className="font-nhaas45 font-size-25">
                            Thomas Sabatier, président.
                        </div>
                        <div className="font-nhaas45 font-size-25">
                            10 D rue Alexandre Coutureau, 92210 Saint-Cloud
                        </div>
                        <br></br>
                        <div className="font-size-35 font-recoletamedium">Hébergeur</div>
                        <div className="font-nhaas45 font-size-25">
                            OVH – 2 rue Kellermann – 59100 Roubaix – France

                        </div>
                        <div className="font-nhaas45 font-size-25">
                            Certains flux vidéos sont hébergés par la société YouTube
                        </div>
                        <br></br>
                        <div className="font-size-35 font-recoletamedium">Conditions générales d’utilisation du site</div>
                        <div className="font-nhaas45 font-size-25">
                            – L’utilisation du site implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites.
                            Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs du site sont donc invités à les consulter de manière régulière.
                        </div>
                        <div className="font-nhaas45 font-size-25">
                            – Ce site est normalement accessible à tout moment aux utilisateurs. Une interruption pour raison de maintenance technique peut être toutefois décidée par PITCHICHI, qui s’efforcera alors de communiquer préalablement aux utilisateurs les dates et heures de l’intervention.
                        </div>
                        <div className="font-nhaas45 font-size-25">
                            – Le site est mis à jour régulièrement par PITCHICHI. De la même façon, les mentions légales peuvent être modifiées à tout moment : elles s’imposent néanmoins à l’utilisateur qui est invité à s’y référer le plus souvent possible afin d’en prendre connaissance.
                        </div>

                        <br></br>

                        <div className="font-size-35 font-recoletamedium">Description des services fournis</div>
                        <div className="font-nhaas45 font-size-25">
                            – Le site a pour objet de fournir une information concernant l’ensemble des activités de la société.
                        </div>
                        <div className="font-nhaas45 font-size-25">
                            – PITCHICHI s’efforce de fournir sur le site des informations aussi précises que possible. Toutefois, il ne pourra être tenue responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.
                        </div>
                        <div className="font-nhaas45 font-size-25">
                            – Toutes les informations indiquées sur le site sont données à titre indicatif, et sont susceptibles d’évoluer. Par ailleurs, les renseignements figurant sur le site ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.
                        </div>

                        <br></br>

                        <div className="font-size-35 font-recoletamedium">Limitations contractuelles sur les données techniques</div>
                        <div className="font-nhaas45 font-size-25">
                            – Le site utilise la technologie Python.
                        </div>
                        <div className="font-nhaas45 font-size-25">
                            – Le site Internet ne pourra être tenu responsable de dommages matériels liés à l’utilisation du site. De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour.
                        </div>

                        <br></br>

                        <div className="font-size-35 font-recoletamedium">Propriété intellectuelle</div>
                        <div className="font-nhaas45 font-size-25">
                            – PITCHICHI est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels.

                        </div>
                        <div className="font-nhaas45 font-size-25">
                            – Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de : PITCHICHI.

                        </div>
                        <div className="font-nhaas45 font-size-25">
                            – Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.

                        </div>

                        <br></br>

                        <div className="font-size-35 font-recoletamedium">Limitations de responsabilité</div>
                        <div className="font-nhaas45 font-size-25">
                            - PITCHICHI ne pourra être tenue responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site, et résultant soit de l’utilisation d’un matériel ne répondant pas aux spécifications indiquées ci-dessus, soit de l’apparition d’un bug ou d’une incompatibilité.

                        </div>
                        <div className="font-nhaas45 font-size-25">
                            - PITCHICHI ne pourra également être tenue responsable des dommages indirects (tels par exemple qu’une perte de marché ou perte d’une chance) consécutifs à l’utilisation du site.

                        </div>
                        <div className="font-nhaas45 font-size-25">
                            – Des espaces interactifs (possibilité de poser des questions dans l’espace contact, fiches agences) sont à la disposition des utilisateurs. PITCHICHI se réserve le droit de supprimer, sans mise en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la législation applicable en France, en particulier aux dispositions relatives à la protection des données. Le cas échéant, PITCHICHI se réserve également la possibilité de mettre en cause la responsabilité civile et/ou pénale de l’utilisateur, notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie…).

                        </div>

                        <br></br>

                        <div className="font-size-35 font-recoletamedium">Liens hypertextes</div>
                        <div className="font-nhaas45 font-size-25">
                            – Le site contient un certain nombre de liens hypertextes vers d’autres sites, mis en place avec l’autorisation de PITCHICHI.
                            Cependant, PITCHICHI n’a pas la possibilité de vérifier le contenu des sites ainsi visités, et n’assumera en conséquence aucune responsabilité de ce fait.
                        </div>

                        <br></br>

                        <div className="font-size-35 font-recoletamedium">Droit applicable et attribution de juridiction</div>
                        <div className="font-nhaas45 font-size-25">
                            – Tout litige en relation avec l’utilisation du site est soumis au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Paris.
                        </div>
                        <br></br>
                    </Col>
                </Row>
            </Container>

        </>
    );
}

