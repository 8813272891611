import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Footer from "./components/Footer";
import CookieConsent from "react-cookie-consent";

import Navigation from "./components/Navigation";
import About from './views/About/About';
import Contact from './views/Contact/Contact';
import Home from './views/Home/Home';
import Firm from "./views/Firm/Firm";
import Offers from "./views/Offers/Offers";
import Guide from "./views/Guide/Guide";
import Cases from './views/Cases/Cases';
import Agencies from './views/Agencies/Agencies';
import Wizard from "./views/Wizard/Wizard";
import WizardCompleted from "./views/Wizard/WizardCompleted";
import Agency from './views/Agency/Agency';
import Politics from './views/Politics/Politics'
import Agenciescategory from './views/Agenciescategory/Agenciescategory';
import Legal from './views/Legal/Legal';



function App() {
  return (
    <>
      <Navigation />
      <div className="wraper">

      </div>
      <Switch>
        <Route exact path="/liste-des-meilleures-agences-360-communication-creative-globale">
          <Redirect to="/liste-des-meilleures-agences-Publicité" />
        </Route>
        <Route exact path="/liste-des-meilleures-agences-luxe-mode-design-horlogerie-joaillerie">
          <Redirect to="/liste-des-meilleures-agences-Luxe" />
        </Route>
        <Route exact path="/liste-meilleures-agences-digitales-site-mobile-social-media-data">
          <Redirect to="/liste-des-meilleures-agences-Digital" />
        </Route>
        <Route exact path="/liste-des-meilleures-agences-branding-graphisme-identite-visuelle">
          <Redirect to="/liste-des-meilleures-agences-Branding" />
        </Route>
        <Route exact path="/guide-trouver-agence-communication-publicite">
          <Redirect to="/guide-meilleures-agences-pub-com" />
        </Route>
        <Route exact path='/' component={Home} />
        <Route path='/histoire-pitchichi-thomas-sabatier' component={About} />
        <Route path='/offres-recherche-agences-communication' component={Offers} />
        <Route path='/guide-meilleures-agences-pub-com' component={Guide} />
        <Route path='/contact-pitchichi' component={Contact} />
        <Route path='/competition-agence-communication' component={Firm} />
        <Route path='/cas-client-:slug' component={Cases} />
        <Route exact path='/liste-meilleures-agences-communication-pub-branding-marque-France' component={Agencies} />
        <Route path=':slug' component={Agency} />
        <Route path='/wizard' component={Wizard} />
        <Route path='/wizard-completed' component={WizardCompleted} />
        <Route path='/liste-des-meilleures-agences-:slug' component={Agenciescategory} />
        <Route path='/liste-des-meilleures-agences-Publicité' component={Agenciescategory} />
        <Route path='/politique-confidentialite' component={Politics} />
        <Route path='/informations-légales' component={Legal} />
        <Route path='/*' component={Agency} />
       
        {/* <Route path="/*" render={() => (
          <Redirect to="/" />
        )} /> */}
      </Switch>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="J'accepte"
        cookieName="pitchichi-cookie"
        declineButtonText="Je refuse"
        disableDeclineButton
        buttonStyle={{ color: "#4e503b", fontSize: "13px", fontWeight: "bold" }}
        style={{ background: "#2B373B" }}
        expires={150}
        buttonWrapperClasses="wrapper-buttons"
        containerClasses="wrapper-cookies"
        contentClasses="wrapper-cookies-content"
      >
        Ce site utilise les cookies, y compris ceux de tiers, afin d’améliorer votre expérience sur notre site et de réaliser des mesures d’audience{" "}
        {/*  <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
      </CookieConsent>
    </>
  );
}

export default App;
