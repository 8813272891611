import "../../../App.scss";
import React from 'react';
import "./SectionE.scss";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";





export default function SectionE() {
    return (

        <Container fluid className="bg-white home-section-e d-flex align-items-center justify-content-center text-primary">
            <Row className="m-auto card-section align-items-center">
                <Col xs={6} md={4}>
                    <Row className="text-center flex-column">
                        <div className="font-size-section-e-45 font-recoletasemibold">+ de 600</div>
                        <div className="line-height-1 font-size-section-e-18 font-nhaas55">
                            <div>agences identifiées</div>
                            <div>sur environ 15 000 en France</div>
                        </div>
                    </Row>
                </Col>
                <Col xs={6} md={4}>
                    <Row className="text-center flex-column">
                        <div className="font-size-section-e-45 font-recoletasemibold">+ de 95%</div>
                        <div className="line-height-1 font-size-section-e-18 font-nhaas55">
                            <div>d'annonceurs satisfaits</div>
                            <div>de leur recommandation</div>
                        </div>
                    </Row>
                </Col>
                <Col xs={12} md={4}>
                    <Row className="text-center flex-column">
                        <div className="font-size-section-e-45 font-recoletasemibold">1 Label</div>
                        <div className="line-height-1 font-size-section-e-18 font-nhaas55">
                            <div>de qualité créative</div>
                            <div>Sélection des agences sur dossier</div>
                        </div>
                    </Row>
                </Col>
            </Row>
        </Container >
    )
}