import "../../App.scss";
import React, { useState, useEffect } from "react";
import "./SectionI.scss";
import "../../components/Carousel.scss";
import axios from 'axios'
import { PRESS_URL } from "../../util/Constant";
import Carousel, {
    arrowsPlugin,
    slidesToShowPlugin,
    infinitePlugin,
    Dots
} from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import arrowLeft from "../../assets/carousel-arrow-left.svg";
import arrowRIght from "../../assets/carousel-arrow-right.svg";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import disableScroll from 'disable-scroll';



export default function SectionI(props) {
    const [Slides, setSlides] = useState([])
    const [displayPress, setdisplayPress] = useState(false)
    const getPress = () => {
        axios.get(PRESS_URL)
            .then((response) => {
                const filtered = response?.data?.filter(value => value.displayed_on_consulting_page === true)
                const slides = filtered.map(p => {
                    return <div className="d-flex sectioni-carousel flex-column justify-content-between px-3 px-md-5 text-left sectionh-card  text-primary font-nhaas45 font-size-25 line-height-1">
                            <div>
                                <img src={p?.logo?.file} width="100" className="logo-carousel" alt="" />
                            </div>
                            <div>
                                {p?.title}
                            </div>
                            <div className="font-size-18 link-read-article">
                                → <a href={p?.url} target="_blank" rel="noreferrer">Lire l’article</a>
                            </div>
                        </div>
                })
                setSlides(slides)
                setdisplayPress(true)
            })
            .catch(error => console.error(error))
    }
    
    useEffect(() => {
        getPress();
    }, []);

    const [value, setValue] = useState(0)

    onchange = (value) => {
        setValue(value);
    }
    const onTouchStart = (e) => {
        disableScroll.on();

    }

    const onTouchEnd = (e) => {
        disableScroll.off();

    }
    return (
        <Container
            fluid
            className="home-section-i bg-white py-5 fade-in-slow"
        >
            <Row className="d-flex flex-column text-center justify-content-center align-items-center text-primary mb-3 mb-md-5">


                <div className="line-height-1">
                    <span className="font-size-55 font-nhaas65 text-uppercase">
                        On parle{" "}
                    </span>
                    <br className="d-sm-none d-block"></br>
                    <span className="font-size-55 font-recoletalight text-uppercase">
                        de pitchichi
                    </span>

                </div>
                <div className="mt-2">
                    <span className="font-size-35 font-nhaas45 ">
                        Revue de presse
                    </span>
                </div>
            </Row>
            {displayPress && <div className={`d-flex align-items-center mx-auto carousel flex-column justify-content-center`} onTouchStart={onTouchStart} onTouchEnd={onTouchEnd}>
                <Carousel
                    value={value}
                    onChange={onchange}
                    slides={Slides}
                    plugins={[
                        {
                            resolve: arrowsPlugin,
                            options: {
                                arrowLeft: (
                                    <img src={arrowLeft} alt="" className="arrow" />
                                ),
                                arrowLeftDisabled: (
                                    <img src={arrowLeft} alt="" className="arrow" />
                                ),
                                arrowRight: (
                                    <img src={arrowRIght} alt="" className="arrow" />
                                ),
                                arrowRightDisabled: (
                                    <img src={arrowRIght} alt="" className="arrow" />
                                ),
                                addArrowClickHandler: true,
                            },
                        },
                        {
                            resolve: slidesToShowPlugin,
                            options: {
                                numberOfSlides: 3,
                            },
                        },
                        {
                            resolve: infinitePlugin,
                            options: {
                                numberOfInfiniteClones: 1,
                            },
                        },
                    ]}

                    breakpoints={{
                        767: {
                            plugins: [

                                {
                                    resolve: slidesToShowPlugin,
                                    options: {
                                        numberOfSlides: 3,

                                    }
                                },
                                {
                                    resolve: infinitePlugin,
                                    options: {
                                        numberOfInfiniteClones: 1,
                                    },
                                },

                            ]
                        },
                        570: {
                            plugins: [

                                {
                                    resolve: slidesToShowPlugin,
                                    options: {
                                        numberOfSlides: 3,

                                    }
                                },
                                {
                                    resolve: infinitePlugin,
                                    options: {
                                        numberOfInfiniteClones: 1,
                                    },
                                },

                            ]
                        }
                    }}
                >
                </Carousel>
                <Dots value={value} onChange={onchange} number={Slides?.length} className="d-flex d-md-none" />
            </div>}
        </Container >

    )
}
