
const dotenv = require("dotenv")
dotenv.config()

export const API_URL = (typeof process.env.REACT_APP_API_URL === 'undefined') ? "https://api.preprod.pitchichi.com" : process.env.REACT_APP_API_URL;
 
export const CUSTOMERS_URL = API_URL + "/customers/";
export const PRESS_URL = API_URL + "/press/";
export const AGENCIES_URL = API_URL + "/agencies/";
export const PITCHICHI_CUSTOMER_CASES = API_URL + "/customer-cases/";
export const TESTIMONIALS_URL = API_URL + "/testimonials/"



