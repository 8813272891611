import "../../../App.scss";
import "./SectionA.scss";

import React from "react";
import Container from "react-bootstrap/Container";
import { LinkContainer } from 'react-router-bootstrap'
import Button from "react-bootstrap/Button";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


export default function SectionA() {

    return (
        <>
            <Container fluid className="home-section-a">
                <Row className="vh-100 d-flex bg-green align-items-center bg-green bg-section fade-in-fast hmax-1440">
                    <Col className="d-flex flex-column align-items-center text-primary fs-1 mt-n5 mt-n10 fade-in-slow">
                        <div className="font-size-90 text-uppercase line-height-09 "><span className="font-recoletalight">en</span>{" "}<span className="font-nhaas65">quête</span></div>
                        <div className="font-size-90 text-uppercase font-recoletalight line-height-09">D'une nouvelle</div>
                        <div className="font-size-90 text-uppercase font-nhaas65 line-height-09">agence{" "}<span className="font-recoletalight">?</span></div>
                        <div className="text-center sub-title mt-1 mt-md-4 mb-md-3">
                            <div className="font-size-33 font-nhaas45 line-height-1">Pitchichi vous recommande les agences de communication</div>
                            <div className="font-size-33 font-nhaas45 line-height-1">parfaitement adaptées à votre projet.</div>
                        </div>
                        <LinkContainer to="/wizard">
                            <Button variant="yellow" className="btn-pitchichi-primary mt-1 mt-md-4">Trouver mon agence</Button>
                        </LinkContainer>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
