import "../../App.scss";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import axios from 'axios'
import { API_URL } from "../../util/Constant";
import "./Agency.scss";
import Button from "react-bootstrap/Button";
import { LinkContainer } from 'react-router-bootstrap'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import facebook from "../../assets/icons/logo-facebook.svg"
import instagram from "../../assets/icons/logo-instagram.svg"
import twitter from "../../assets/icons/logo-twitter.svg"
import linkedin from "../../assets/icons/logo-linkedin.svg"

export default function Agency(props) {
    const [agency, setAgency] = useState('')
    const [references, setReferences] = useState('')
    const history = useHistory()
    const [display, setdisplay] = useState(false)

    const getAgency = () => {
        axios.get(API_URL + "/agencies" + props.location.pathname)
            .then((response) => {
                setAgency(response.data)
                getAgencyReferences(response.data.slug)
                setdisplay(true)
            })
            .catch(error =>
                history.push('/')
            )
    }

    const socialLinks = (socials) => {
        const socialsfinal = []
        socials?.forEach(element => {
            if (element.url !== "") {
                socialsfinal.push(element)
                if (element.label === "Facebook") {
                    element.logo = facebook
                } else if (element.label === "Site") {
                    element.logo = linkedin
                } else if (element.label === "Instagram") {
                    element.logo = instagram
                } else if (element.label === "Twitter") {
                    element.logo = twitter
                }
            }
        });
        return socialsfinal.map(item => (
            <div className="">
                <a target="_blank" rel="noreferrer" href={item.url}><img src={item.logo} className="ml-1 ml-md-2" width="25" alt="Pitchichi" /></a>

            </div>


        ))

    }



    const getAgencyReferences = (slug) => {
        axios.get(API_URL + "/agencies/" + slug + "/customer-cases/")
            .then((response) => {
                setReferences(response.data)
            })
            .catch(error => history.push('/'))
    }

    useEffect(() => {
        getAgency()/* ;
        getAgencyReferences() */
    }, []);
    return (
        <>
            <Helmet>
                <title>Pitchichi | {agency?.name} | {agency?.city} | Fiche agence de communication
                </title>
            </Helmet>

            <div className="bg-white"><Container fluid className="bg-white agency-main-container d-flex flex-column justify-content-center fade-in-slow text-primary wmax-2160">
                <Row className="p-1 m-md-5">
                    <Col className="agency-main-card p-0 d-flex flex-column justify-content-center align-items-center bg-secondary">


                        {agency?.office_picture ? <Row className="justify-content-center w-100 border-radius-26 position-relative" >
                            <div className="agency-main-card-top w-100 border-radius-26" style={{ backgroundImage: `url(${agency?.office_picture?.file})` }}>
                                <div className="agency-main-logo" style={{ backgroundImage: `url(${agency?.logo?.file})` }}></div>
                            </div>
                        </Row> : <Row className="justify-content-center w-100 border-radius-26 position-relative" >
                            <div className="agency-main-card-top-empty w-100 border-radius-26" style={{ backgroundImage: `url(${agency?.office_picture?.file})` }}>
                                <div className="agency-main-logo-empty" style={{ backgroundImage: `url(${agency?.logo?.file})` }}></div>
                            </div>
                        </Row>}

                        <Row className="justify-content-center agency-main-card-bottom mt-0 mt-md-0 position-relative">
                            <Col xs={12} className="d-flex social-media justify-content-end align-items-center position-absolute mt-0 mt-md-2 mr-0 mr-md-3">
                                {socialLinks(agency?.social_media)}
                            </Col>
                            <Col xs={12} className="d-flex flex-column justify-content-center align-items-center font-nhaas65 font-size-90 my-4 ">
                                {agency?.name}
                            </Col>
                            <Col xs={10} sm={7} className="d-flex flex-column justify-content-center align-items-center text-center font-nhaas45 font-size-35 line-height-11">
                                {agency?.description}
                            </Col>
                            {display && <Col xs={12} sm={12} className="d-flex flex-column justify-content-center my-3 my-md-5">
                                <Row className="text-center px-3 justify-content-center">
                                    <Col xs={4} lg={2} className="font-recoletasemibold font-size-44 px-1 my-3 ">
                                        {agency?.holding_name
                                            ? <><div className="font-recoletasemibold font-size-44 line-height-1 mb-2 text-break">
                                                {agency?.holding_name}

                                            </div><div className="font-nhaas55 font-size-18">
                                                    groupe
                                                </div></>
                                            : <span>Indépendant</span>
                                        }
                                    </Col>
                                    <Col xs={4} lg={2} className="px-1 my-3">
                                        <div className="font-recoletasemibold font-size-44  line-height-1 mb-2">
                                            {agency?.founding_year}

                                        </div>
                                        <div className="font-nhaas55 font-size-18">
                                            année de création
                                        </div>
                                    </Col>
                                    <Col xs={4} lg={2} className="px-1 my-3">
                                        <div className="font-recoletasemibold font-size-44  line-height-1 mb-2">
                                            {agency?.effectif_total}
                                        </div>
                                        <div className="font-nhaas55 font-size-18">
                                            personnes
                                        </div>
                                    </Col>
                                    <Col xs={6} lg={2} className="px-1 my-3 text-break">
                                        <div className="font-recoletasemibold font-size-44  line-height-1 mb-2">
                                            {agency?.city}
                                        </div>
                                        <div className="font-nhaas55 font-size-18 text-break">
                                            {agency?.address}
                                        </div>
                                    </Col>
                                    <Col xs={6} lg={2} className="px-1 my-3">
                                        <div className="font-recoletasemibold font-size-44  line-height-1 mb-2">
                                            Web
                                        </div>
                                        <div className="font-nhaas55 font-size-18 text-break">
                                            <a href={agency?.url} className="agency-link" target="_blank" rel="noreferrer">visiter le site de l'agence</a>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>}

                        </Row>

                    </Col>
                </Row>
                <Row className="d-flex flex-column justify-content-center m-4">
                    <Col className="d-flex font-size-20 flex-column justify-content-center text-center">
                        <div><span className="font-NeueHaasDisplayMedium">Board : </span><span className="font-nhaas55">{agency?.ceo}</span></div>
                        {(agency?.creation_directors?.length > 0 && agency?.creation_directors[0] !== "") && <div><span className="font-NeueHaasDisplayMedium">Directeur{agency?.creation_directors?.length > 1 && <span>s</span>} de la création : </span>{agency?.creation_directors?.map((cd, index) => (<span className="font-nhaas55" key={`demo_snap_${index}`}>{(index ? ', ' : '') + cd}</span>))}</div>}
                        {(agency?.strategy_directors?.length > 0 && agency?.strategy_directors[0] !== "") && <div><span className="font-NeueHaasDisplayMedium">Directeur{agency?.strategy_directors?.length > 1 && <span>s</span>} de la stratégie : </span>{agency?.strategy_directors?.map((sd, index) => (<span className="font-nhaas55" key={`demo_snap_${index}`}>{(index ? ', ' : '') + sd}</span>))}</div>}
                        {(agency?.production_directors?.length > 0 && agency?.production_directors[0] !== "") && <div><span className="font-NeueHaasDisplayMedium">Directeur{agency?.production_directors?.length > 1 && <span>s</span>} de la production : </span>{agency?.production_directors?.map((sd, index) => (<span className="font-nhaas55" key={`demo_snap_${index}`}>{(index ? ', ' : '') + sd}</span>))}</div>}
                        {(agency?.newbiz_directors?.length > 0 && agency?.newbiz_directors[0] !== "") && <div><span className="font-NeueHaasDisplayMedium">Directeur{agency?.newbiz_directors?.length > 1 && <span>s</span>} {agency?.newbiz_directors?.length > 1 ? <span>commerciaux</span> : <span>commercial</span>} : </span>{agency?.newbiz_directors?.map((nb, index) => (<span className="font-nhaas55" key={`demo_snap_${index}`}>{(index ? ', ' : '') + nb}</span>))}</div>}

                    </Col>
                </Row>

                <Row className="d-flex flex-column justify-content-center mb-4 mt-0 mt-sm-4 align-items-center">
                    <Col xs={10} className="d-flex font-size-20 flex-column justify-content-center text-center">
                        {agency?.customers?.length > 0 && <div><span className="font-NeueHaasDisplayMedium">Principaux clients : </span>{agency?.customers?.map((c, index) => (<span className="font-nhaas55" key={`demo_snap_${index}`}>{(index ? ', ' : '') + c.name}</span>))}</div>}

                    </Col>
                </Row>
            </Container></div>

            <Container fluid className="d-flex bg-white justify-content-center align-items-center py-4 py-md-5">
                <Row className="flex-column flex-md-row">
                    <LinkContainer to="/wizard">
                        <Button variant="yellow" className="pitchichi">Choisir cette agence</Button>
                    </LinkContainer>
                </Row>
            </Container>

            <Container fluid className="d-flex bg-white flex-column justify-content-center align-items-center py-4 py-md-5 text-primary">
                <Row className="flex-column flex-md-row font-recoletalight font-size-55 text-uppercase pb-md-3 pb-3">
                    <Col>
                        {references.length > 0 && <div>références</div>}
                    </Col>
                </Row>
                {references && references.map((ref) => (
                    <>
                        <Row className="justify-content-center">
                            <Col xs={12} md={10}>
                                {ref?.images?.map(img => (
                                    <img src={img?.file} alt="pitchichi" className="img-reference" />
                                ))}
                            </Col>
                        </Row>
                        <Row className="justify-content-center w-100">
                            <Col xs={12} md={10} className="text-center my-3">
                                <div className="font-size-20 font-nhaas65">{ref?.name} - {ref?.customer}</div>
                            </Col>
                        </Row>
                    </>
                ))}

            </Container>
        </>
    );
}