import React, { useState, useEffect } from "react";
import "../../../App.scss";
import "./SectionB.scss";
import "../../../components/Carousel.scss";
import axios from 'axios'
import { CUSTOMERS_URL } from "../../../util/Constant";
import disableScroll from 'disable-scroll';

import Carousel, {
    arrowsPlugin,
    slidesToShowPlugin,
    infinitePlugin,
    Dots
} from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import arrowLeft from "../../../assets/carousel-arrow-left.svg";
import arrowRIght from "../../../assets/carousel-arrow-right.svg";
import Container from "react-bootstrap/Container";

export default function SectionB(props) {
    const [displayCustomers, setdisplayCustomers] = useState(false)
    const [Slides, setSlides] = useState([])

    const getCustomers = () => {
        axios.get(CUSTOMERS_URL)
            .then((response) => {
                const filtered = response?.data?.filter(value => value.displayed_on_platform_page === true)
                const slides =
                    filtered.map(p => {
                        return <div className="w-100 d-flex justify-content-center mx-0 mx-md-4 px-4 px-md-0"><img src={p?.logo?.file} width="100%" height="auto" alt="pitchichi"/></div>
                    })
                setSlides(slides)
                setdisplayCustomers(true)
            })
            .catch(error => console.error(error))
    }

    useEffect(() => {
        getCustomers();
    }, []);

    const [value, setValue] = useState(0)

    onchange = (value) => {
        setValue(value);
    }
    const onTouchStart = (e) => {
        disableScroll.on();

    }

    const onTouchEnd = (e) => {
        disableScroll.off();

    }




    return (
        <Container fluid className="bg-white home-section-b pb-5 pb-sm-0">
            {displayCustomers && <div className={`d-flex align-items-center mx-auto carousel flex-column justify-content-center carousel-height`} onTouchStart={onTouchStart} onTouchEnd={onTouchEnd}>
                <Carousel

                    value={value}
                    onChange={onchange}
                    slides={Slides}
                    plugins={[
                        'fastSwipe',
                        {
                            resolve: arrowsPlugin,
                            options: {
                                arrowLeft: (
                                    <img src={arrowLeft} alt="" className="arrow" />
                                ),
                                arrowLeftDisabled: (
                                    <img src={arrowLeft} alt="" className="arrow" />
                                ),
                                arrowRight: (
                                    <img src={arrowRIght} alt="" className="arrow" />
                                ),
                                arrowRightDisabled: (
                                    <img src={arrowRIght} alt="" className="arrow" />
                                ),
                                addArrowClickHandler: true,
                            },
                        },
                        {
                            resolve: slidesToShowPlugin,
                            options: {
                                numberOfSlides: 6,
                            },
                        },
                        {
                            resolve: infinitePlugin,
                            options: {
                                numberOfInfiniteClones: 1,
                            },
                        },
                    ]}

                    breakpoints={{
                        767: {
                            plugins: [

                                {
                                    resolve: slidesToShowPlugin,
                                    options: {
                                        numberOfSlides: 3,

                                    }
                                },
                                {
                                    resolve: infinitePlugin,
                                    options: {
                                        numberOfInfiniteClones: 1,
                                    },
                                },

                            ]
                        },
                        570: {
                            plugins: [

                                {
                                    resolve: slidesToShowPlugin,
                                    options: {
                                        numberOfSlides: 3,

                                    }
                                },
                                {
                                    resolve: infinitePlugin,
                                    options: {
                                        numberOfInfiniteClones: 1,
                                    },
                                },

                            ]
                        }
                    }}
                >
                </Carousel>
                <Dots value={value} onChange={onchange} number={Slides?.length} className="d-flex d-md-none" />
            </div>}
        </Container >
    )
}