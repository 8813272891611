import "../../App.scss";
import React from 'react';
import "./About.scss";
import { LinkContainer } from 'react-router-bootstrap'
import { Helmet } from "react-helmet";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import img from "./../../assets/illustrations/about/section1.svg"
import img2 from "./../../assets/illustrations/about/section3.svg"
import img3 from "./../../assets/illustrations/about/section4.svg"
import card1 from "./../../assets/illustrations/main/card1.png.webp";
import card2 from "./../../assets/illustrations/main/card2.png.webp";

export default function About() {
    return (
        <>
            <Helmet>
                <title>Recommandations d'agences & aventures créatives | Pitchichi | Thomas Sabatier | Experts en communication
                </title>
                <meta name="description" content="La seule plateforme de recommandation d’agences fondée par un ancien créatif en agence de pub." />
                <link rel="pitchichi" href="https://www.pitchichi.com/histoire-pitchichi-thomas-sabatier" />
            </Helmet>
            <main className="about-page text-primary about fade-in-fast ">
                <section id="section-1" className="section-first bg-blue vh-100 d-flex justify-content-center align-items-center hmax-1440 ">
                    <Row className="flex-column-reverse flex-md-row">
                        <Col xs={12} md={6} className="d-flex justify-content-center justify-content-md-end px-0 pr-md-5">
                            <div>
                                <img src={img} alt="pitchichi" className="img-1 pr-md-5" />
                            </div>

                        </Col>
                        <Col xs={12} md={6} className="d-flex flex-column justify-content-md-between">
                            <Row className="flex-column align-items-center align-items-md-start mb-4 mb-md-0 line-height-1 white-space-nowrap">
                                <span className="font-size-55 font-recoletalight text-uppercase">
                                    Trouver la bonne agence,
                                </span>
                                <span className="font-size-55 font-nhaas65 text-uppercase">
                                    <span className="font-size-55 font-recoletalight text-uppercase">
                                        une {" "}
                                    </span>véritable quête{" "}
                                </span>
                                <span className="font-size-55 font-recoletalight text-uppercase">
                                    pour l’annonceur.
                                </span>
                            </Row>
                            <Row className="font-nhaas45 flex-column align-items-center align-items-md-start font-size-35  mb-5 mb-md-0 line-height-1">
                                <div>
                                    Très dense et en perpétuelle mutation,
                                </div>
                                <div >
                                    le marché des agences de communication
                                </div>
                                <div >
                                    est aujourd’hui une jungle dans laquelle
                                </div>
                                <div >
                                    trop d’annonceurs se perdent.
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </section>
                <section id="section-2" className="section-regular bg-blue d-flex justify-content-center mt-5">
                    <Row className="layout">
                        <Col className="d-flex font-nhaas45 flex-column text-center text-md-left font-size-35 font-nhaas45 line-height-1 wmax-2160">
                            <span className="font-recoletasemibold">
                                Notre mission
                            </span>
                            <span className="my-4">
                                Pour leur venir en aide et leur permettre de consulter<br></br>
                                tout de suite les bonnes agences, nous avons créé {" "}
                                <span className="font-recoletasemibold">
                                    Pitchichi.
                                </span>
                            </span>
                            <span className="">
                                Chaque jour,{" "}
                                <span className="font-recoletasemibold">
                                    nous scrutons cet écosystème complexe<br></br>
                                </span>
                                et foisonnant pour y repérer ses meilleurs talents et y<br></br>
                                découvrir de nouvelles tendances.
                            </span>
                            <span className="mt-4">
                                Via notre cabinet de conseil ou notre plateforme digitale,<br></br>
                                grosses et petites entreprises bénéficient de{" "}
                                <span className="font-recoletasemibold">
                                    notre expertise<br></br>unique{" "}
                                </span>
                                pour dénicher la perle rare et atteindre les sommets.
                            </span>
                        </Col>
                    </Row>
                </section>
                <section id="section-3" className="section-regular bg-blue d-flex justify-content-center">
                    <Row className="layout">
                        <Col className="d-flex flex-column text-center text-md-left font-size-35 font-nhaas45 line-height-1">
                            <Card className="card-section3 card-rounded bg-yellow justify-content-center align-items-center text-primary">
                                <Card.Body className="d-flex flex-column p-0 justify-content-center position-relative">
                                    <Card.Img variant="top" src={img2} className="" />
                                    <Card.Title>
                                        <span className="font-size-35 font-recoletasemibold">Pitchichi,</span>
                                    </Card.Title>
                                    <Card.Text className="line-height-1 ">
                                        <div>
                                            <span className="font-nhaas45">l’oiseau rare de la com’ qui survole en permanence la
                                                jungle des agences pour la connaître sur le bout des plumes. Il se fait toujours une joie d'accompagner les
                                                marques dans leur quête de nouveaux territoires. Contrairement aux autres tétrapodes de son espèce qui ont tendance
                                                à tout répéter, Pitchichi est un adepte de la créativité.</span>
                                        </div>

                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </section>
                <section id="section-4" className="section-regular bg-blue d-flex justify-content-center align-items-center">
                    <Row className="flex-column flex-md-row">
                        <Col xs={12} md={5} className="d-flex justify-content-center justify-content-md-end pr-md-5">
                            <img src={img3} alt="pitchichi" className="img-1 pr-0 pr-sm-5" />
                        </Col>
                        <Col xs={12} md={7} className="d-flex flex-column justify-content-center">
                            <Row className="flex-column align-items-center align-items-md-start my-4 my-md-0">
                                <span className="font-size-35 font-recoletasemibold">
                                    Thomas Sabatier,
                                </span>
                            </Row>
                            <Row className="flex-column align-items-center align-items-md-start font-size-28 font-nhaas45 mb-5 mb-md-0 line-height-11">

                                <span className="owner-description text-center text-md-left">
                                    le fondateur de Pitchichi, est un créatif passé dans des agences françaises
                                    réputées (Publicis, Havas, WPP) et dont le travail fut reconnu par le Club
                                    des Directeurs Artistiques et le D&AD. Diplômé du Master « Communication et
                                    Marketing » de l’Université Paris-Dauphine, il met aujourd’hui sa créativité au
                                    service de la relation annonceur-agence.
                                </span>
                            </Row>
                        </Col>
                    </Row>
                </section>
                <section className="home-section-c bg-secondary d-flex flex-column align-items-center justify-content-center">
                    <div className="d-flex flex-column align-items-center justify-content-center text-primary">
                        <div className="title font-size-55 d-flex flex-column text-center">
                            <span className="font-recoletalight text-uppercase">nos deux{" "}<span className="font-nhaas65 text-uppercase">
                                services
                            </span></span>
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row card-section align-items-center justify-content-between mb-5 pb-5">
                        <Card className="card-section3 mr-0 mr-md-4 card-rounded bg-green text-center align-items-center text-primary">
                            <Card.Img variant="top" src={card1} className="mx-auto card-left" />
                            <Card.Body className="d-flex justify-content-around justify-content-md-end flex-column align-items-end align-items-md-center">
                                <Card.Title className="align-self-center">
                                    <span className="font-size-20 font-recoletaregular text-uppercase text-underline">J’AI UN BUDGET ENTRE</span>
                                    <span className="font-size-20 font-recoletasemibold text-uppercase text-underline"> 10</span>
                                    <span className="font-size-20 font-recoletaregular text-uppercase text-underline"> ET</span>
                                    <span className="font-size-20 font-recoletasemibold text-uppercase text-underline"> 100K€</span>
                                    <span className="font-size-20 font-recoletaregular text-uppercase text-underline">*,</span>
                                </Card.Title>
                                <Card.Text className="description line-height-1 text-left text-md-center mb-1 mb-md-4">
                                    <div>
                                        <span className="font-size-44 font-recoletalight">Je cherche</span>
                                    </div>
                                    <div>
                                        <span className="font-size-44 font-nhaas65 text-uppercase">la perle rare</span>
                                    </div>
                                </Card.Text>
                                <Card.Text className="description font-nhaas55 font-size-18 line-height-1 line-height-1  mb-2 mb-md-5 text-left text-md-center">
                                    <div>Gratuit et sans engagement, Pitchichi vous recommande</div>
                                    <div>5 agences parfaitement adaptées.</div>

                                </Card.Text>
                                <LinkContainer to="/wizard">
                                    <Button className="btn-pitchichi-secondary align-self-center mb-0 mb-md-4">Trouver mon agence</Button>
                                </LinkContainer>
                            </Card.Body>
                        </Card>
                        <Card className="card-section3 mt-3 mt-md-0 ml-md-4 ml-0 card-rounded bg-blue text-center align-items-center text-primary">
                            <Card.Img variant="top" src={card2} className="mx-auto card-right" />
                            <Card.Body className="d-flex justify-content-around justify-content-md-end flex-column align-items-end align-items-md-center">
                                <Card.Title className="align-self-center">
                                    <span className="font-size-20 font-recoletaregular text-uppercase text-underline">J’AI UN BUDGET SUPÉRIEUR À</span>

                                    <span className="font-size-20 font-recoletasemibold text-uppercase text-underline"> 100K€</span>
                                    <span className="font-size-20 font-recoletaregular text-uppercase text-underline">*,</span>
                                </Card.Title>
                                <Card.Text className="description line-height-1 text-left text-md-center mb-1 mb-md-4">
                                    <div>
                                        <span className="font-size-44 font-recoletalight">Je vise</span>
                                    </div>
                                    <div>
                                        <span className="font-size-44 font-nhaas65 text-uppercase">les sommets</span>
                                    </div>
                                </Card.Text>
                                <Card.Text className="description font-nhaas55 font-size-18 line-height-1 mb- mb-md-5 text-left text-md-center">
                                    <div>Pitchichi vous propose ses conseils en choix d’agences</div>
                                    <div>et organise votre appel d’offres de A à Z</div>

                                </Card.Text>
                                <LinkContainer to="/competition-agence-communication">
                                    <Button className="btn-pitchichi-primary align-self-center mb-0 mb-md-4">Consulter le cabinet de conseil</Button>
                                </LinkContainer>
                            </Card.Body>
                        </Card>

                    </div>
                </section>
            </main>

        </>
    );
}