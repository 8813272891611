import "../../App.scss";
import "./Contact.scss";
import React, { useState, useEffect } from "react";
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import section3card2 from "../../assets/illustrations/section3/section3-card2.png.webp";
import { API_URL } from "../../util/Constant";



export default function Contact() {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [showModal, setShowModal] = useState(false)
    const handleCloseModal = () => setShowModal(false);

    const onSubmit = (data) => {

        axios.post(API_URL + "/contact/", data)
            .then((response) => {
                setShowModal(true)
            })
            .catch(error => console.error(error))

    };
    useEffect(() => {
        setValue("first_name", "");
        setValue("last_name", "");
        setValue("email", "");
        setValue("phone", "");
        setValue("message", "");
    }, [])

    return (
        <>
            <Helmet>
                <title>Contact | Recommandations & conseil en choix d'agences de communication | Pitchichi
                </title>
            </Helmet>
            <Container fluid className="contact bg-secondary flex-column justify-content-center d-flex fade-in-fast">
                <Row className="text-center ">
                    <Col className="align-items-center justify-content-center d-flex flex-column text-primary">
                        <div className="title">
                            <span className="font-size-55 font-recoletalight text-uppercase">
                                CONTACTEZ-NOUS
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center mt-4 mt-md-0">
                    <Col xs={12} md={6} className="d-flex flex-column font-size-30 font-nhaas45 text-primary">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row className="">
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label className="d-none d-md-block mb-1">Nom *</Form.Label>
                                    <Form.Control type="text" placeholder="Nom *" autoComplete="off" name="first_name" {...register('first_name', { required: true })} />
                                    {errors.first_name && (
                                        <span className="required-error small text-danger">Champs obligatoire</span>
                                    )}
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Label className="d-none d-md-block mb-1">Prénom *</Form.Label>
                                    <Form.Control type="text" placeholder="Prénom" autoComplete="off" name="last_name" {...register("last_name", { required: true })} />
                                    {errors.last_name && (
                                        <span className="required-error small text-danger">Champs obligatoire</span>
                                    )}
                                </Form.Group>
                            </Row>
                            <Row className="">
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label className="d-none d-md-block mb-1">Adresse Email *</Form.Label>
                                    <Form.Control type="email" placeholder="Adresse Email *" autoComplete="off" name="email" {...register("email", { required: true })} />
                                    {errors.email && (
                                        <span className="required-error small text-danger">Champs obligatoire</span>
                                    )}
                                </Form.Group>


                            </Row>
                            <Row className="">
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label className="d-none d-md-block mb-1">Message *</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: '150px' }}
                                        placeholder="Message"
                                        autoComplete="off"
                                        name="message" {...register("message", { required: true })}
                                    />
                                    {errors.message && (
                                        <span className="required-error small text-danger">Champs obligatoire</span>
                                    )}
                                </Form.Group>


                            </Row>
                            <Row className="">
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label className="d-none d-md-block mb-1">Téléphone (pour être rappelé(e)) *</Form.Label>
                                    <Form.Control type="tel" placeholder="Téléphone (pour être rappelé(e))" autoComplete="off" name="phone" {...register("phone", { required: true, minLength: 10, maxLength: 10 })} />
                                    {errors.phone && errors.phone.type === "required" && (
                                        <span className="required-error small text-danger">Champs obligatoire</span>
                                    )}
                                    {errors.phone && (errors.phone.type === "minLength" || errors.phone.type === "maxLength") && (
                                        <span className="required-error small text-danger">Numéro de téléphone non valide</span>
                                    )}
                                </Form.Group>


                            </Row>
                            <Row className="justify-content-center mt-3 mt-md-5">
                                <Button variant="blue" type="submit" className="btn-pitchichi-blue">
                                    Envoyer
                                </Button>
                            </Row>

                        </Form>
                    </Col>
                </Row>
            </Container>
            <Container fluid className=" py-5 bg-secondary flex-column justify-content-center d-flex fade-in-slow">
                <Row className="justify-content-center">
                    <Col xs={6} sm={2} className="d-flex justify-content-center font-size-30 font-nhaas45 text-primary px-5">
                        <img src={section3card2} alt="pitchichi" className="telephone-contact" />
                    </Col>
                </Row>
                <Row className="justify-content-center mt-3">
                    <Col className="d-flex flex-column font-size-30 font-nhaas45 text-primary">
                        <div className="text-center">
                            <span className="font-size-28 font-NHaas45">
                                ou par téléphone au
                            </span>{" "}
                            <span className="font-size-28 font-recoletasemibold">
                                <a href="tel:+33952632488">09.52.63.24.88</a>
                            </span>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Modal show={showModal} onHide={handleCloseModal} centered>

                <Modal.Body>
                    <div class="font-size-55 font-nhaas65 text-primary">Merci !</div>
                    <div class="font-size-35 font-recoletalight text-primary">Nous reviendrons vers vous dans les meilleurs délais.</div>
                </Modal.Body>
                <Modal.Footer>
                    <a href="/home" class="btn-pitchichi-primary btn btn-yellow" onClick={handleCloseModal}>Revenir à l'accueil</a>
                </Modal.Footer>
            </Modal>
        </>
    );
}