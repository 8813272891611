import "../../../App.scss";
import React, { useState, useEffect } from "react";
import axios from 'axios'

import { PITCHICHI_CUSTOMER_CASES } from "../../../util/Constant";
import "./SectionG.scss";

import { LinkContainer } from 'react-router-bootstrap'


import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


export default function SectionGfirm(props) {

    const [customerCases, setCustomerCases] = useState([])
    const getCustomerCases = () => {
        axios.get(PITCHICHI_CUSTOMER_CASES)
            .then((response) => {
                setCustomerCases(response.data)
            })
            .catch(error => console.error(error))
    }

    useEffect(() => {
        getCustomerCases()
    }, []);


    return (
        <Container fluid className="bg-white home-section-g justify-content-around flex-column d-flex p-0 position-relative">
            <div className="title ml-0 pl-md-5 line-height-1">
                <div className="py-4 py-md-0">
                    <span className="font-size-24 font-recoletasemibold">
                        Galerie de cas clients
                    </span>
                </div>
                <div className="">
                    <span className="font-size-90 font-recoletalight text-uppercase">
                        ils sont passés
                    </span>
                </div>
                <div className="">
                    <span className="font-size-90 font-recoletalight text-uppercase">
                        par {" "}
                    </span>
                    <span className="font-size-90 font-nhaas65 text-uppercase">
                        pitchichi
                    </span>
                </div>
                <div className="">
                    <span className="font-size-35 font-nhaas45">
                        Projets réalisés par des agences{" "}
                    </span>
                </div>
                <div className="">
                    <span className="font-size-35 font-nhaas45">
                        recommandées par Pitchichi
                    </span>
                </div>
            </div>
            <Row className="fade-in-medium w-100 mx-0 mt-auto bg-black">

                {customerCases?.map(cc => {
                    if (cc.displayed_on_consulting_page)
                        return <Col className="p-0" xs={6} md={3}>
                            <LinkContainer to={`/cas-client-${cc.slug}`}>
                                <div className="container-link-to-cases">
                                    <img as="link" src={cc?.thumbnail?.file} href={`/cas-client-${cc.slug}`} width="100%" height="auto" alt="Pitchichi" className="link-to-cases" />
                                    <div className="middle">
                                        <div className="text font-size-35 font-recoletasemibold">{cc?.customer}</div>
                                    </div>
                                </div>
                            </LinkContainer>

                        </Col>
                    return null
                })}
            </Row>

        </Container>

    )
}
