import "../App.scss";
import "./Footer.scss"

import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
export default function Footer() {

    return (
        <>
            <Container fluid className="footer flex-column d-none d-md-flex justify-content-center font-size-20 font-nhaas45 bg-primary text-secondary">
                <Row className="w-100 h-100 flex-md-row align-items-end text-nowrap flex-nowrap">
                    <Col className="d-flex px-0 py-3 py-md-0 flex-column flex-md-row">
                        <div className=" px-md-3 pb-0">
                            <span className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-3">Nos guides des agences :</span>
                        </div>
                    </Col>
                </Row>
                <Row className="w-100 h-100 flex-md-row align-items-end text-nowrap flex-nowrap">
                    <Col className="d-flex px-0 py-3 py-md-0 flex-column flex-md-row">
                        <div className=" px-md-3 pb-0">
                            <a href="/liste-des-meilleures-agences-Branding" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Branding</a>
                            <a href="/liste-des-meilleures-agences-Digital" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Digital</a>
                            <a href="/liste-des-meilleures-agences-Publicité" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Publicité</a>
                            <a href="/liste-des-meilleures-agences-Luxe" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Luxe</a>
                            <a href="/liste-des-meilleures-agences-Social media" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Social Media</a>
                            <a href="/liste-des-meilleures-agences-Expérientiel" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 pt-0 pb-3 nav-link" role="button">Agence communication Expérientiel</a>
                            <a href="/politique-confidentialite" className="font-size-20 font-nhaas45 text-secondary px-0 py-0 py-md-3 nav-link" role="button">Politique de confidentialité</a>
                        </div>
                        <div className="px-md-3 pb-0">
                            <a href="/liste-des-meilleures-agences-Paris" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Paris</a>
                            <a href="/liste-des-meilleures-agences-Lyon" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Lyon</a>
                            <a href="/liste-des-meilleures-agences-Nantes" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Nantes</a>
                            <a href="/liste-des-meilleures-agences-Lille" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Lille</a>
                            <a href="/liste-des-meilleures-agences-Marseille" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Marseille</a>
                            <a href="/liste-des-meilleures-agences-Bordeaux" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 pt-0 pb-3 nav-link" role="button">Agence communication Bordeaux</a>
                            <a href="/informations-légales" className="font-size-20 font-nhaas45 text-secondary px-0 py-0 py-md-3 nav-link" role="button" style={{ marginLeft: "-80px" }}>Informations légales</a>
                        </div>
                        {/* <div className=" p-md-3">
                            <a href="/" className="font-size-20 font-nhaas45 text-secondary px-0 py-0 nav-link" role="button">FAQ</a>
                        </div> */}
                        <div className="px-md-3 pb-0">
                            <a href="liste-des-meilleures-agences-Nord" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Nord</a>
                            <a href="liste-des-meilleures-agences-Ouest" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Ouest</a>
                            <a href="liste-des-meilleures-agences-Est" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Est</a>
                            <a href="liste-des-meilleures-agences-Centre" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Centre</a>
                            <a href="liste-des-meilleures-agences-Sud-Ouest" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Sud-Ouest</a>
                            <a href="liste-des-meilleures-agences-Sud-Est" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 pt-0 pb-3 nav-link" role="button">Agence communication Sud-Est</a>
                            <a href="/contact-pitchichi" className="font-size-20 font-nhaas45 text-secondary px-0 py-0 py-md-3 nav-link" style={{ marginLeft: "-170px" }} role="button">Contact</a>
                        </div>
                    </Col>
                    <Col className="d-flex flex-column align-items-end justify-content-start justify-content-md-end px-0 py-3 py-md-0 text-right">
                        <div className="p-0 p-md-3 text-md-right line-height-12">
                            Pitchichi<br></br>
                            75 rue d'Amsterdam<br></br>
                            75008 Paris<br></br>
                            <a className="text-secondary" href="tel:+33952632488">09.52.63.24.88</a>
                        </div>
                        <div className="p-0 p-md-3 text-md-right">
                            Copyright © 2022 Pitchichi. Tous droits réservés

                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="footer flex-column d-flex d-md-none justify-content-center font-size-20 font-nhaas45 bg-primary text-secondary px-4">
                <Row className="w-100 h-100 flex-md-row align-items-end text-nowrap flex-nowrap">
                    <Col className="d-flex px-0 py-3 py-md-0 flex-column flex-md-row">
                        <div className=" px-md-3 pb-0">
                            <span className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0">Nos guides des agences :</span>
                        </div>
                    </Col>
                </Row>
                <Row className="w-100 h-100 flex-md-row align-items-end text-nowrap flex-nowrap">
                    <Col className="d-flex px-0 py-md-0 flex-column flex-md-row">
                        <div className=" px-md-3 pb-0">
                            <a href="/liste-des-meilleures-agences-Branding" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Branding</a>
                            <a href="/liste-des-meilleures-agences-Digital" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Digital</a>
                            <a href="/liste-des-meilleures-agences-Publicité" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Publicité</a>
                            <a href="/liste-des-meilleures-agences-Luxe" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Luxe</a>
                            <a href="/liste-des-meilleures-agences-Social media" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Social Media</a>
                            <a href="/liste-des-meilleures-agences-Expérientiel" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 pt-0 pb-3 nav-link" role="button">Agence communication Expérientiel</a>

                        </div>
                        <div className="px-md-3 pb-0">
                            <a href="/liste-des-meilleures-agences-Paris" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Paris</a>
                            <a href="/liste-des-meilleures-agences-Lyon" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Lyon</a>
                            <a href="/liste-des-meilleures-agences-Nantes" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Nantes</a>
                            <a href="/liste-des-meilleures-agences-Lille" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Lille</a>
                            <a href="/liste-des-meilleures-agences-Marseille" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Marseille</a>
                            <a href="/liste-des-meilleures-agences-Bordeaux" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 pt-0 pb-3 nav-link" role="button">Agence communication Bordeaux</a>

                        </div>
                        {/* <div className=" p-md-3">
                            <a href="/" className="font-size-20 font-nhaas45 text-secondary px-0 py-0 nav-link" role="button">FAQ</a>
                        </div> */}
                        <div className="px-md-3 pb-0">
                            <a href="liste-des-meilleures-agences-Nord" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Nord</a>
                            <a href="liste-des-meilleures-agences-Ouest" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Ouest</a>
                            <a href="liste-des-meilleures-agences-Est" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Est</a>
                            <a href="liste-des-meilleures-agences-Centre" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Centre</a>
                            <a href="liste-des-meilleures-agences-Sud-Ouest" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 py-0 nav-link" role="button">Agence communication Sud-Ouest</a>
                            <a href="liste-des-meilleures-agences-Sud-Est" className="font-size-20 font-nhaas45 text-secondary  d-md-block px-0 pt-0 pb-3 nav-link" role="button">Agence communication Sud-Est</a>

                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col className="d-flex flex-column align-items-start justify-content-start justify-content-md-end px-0 py-3 py-md-0 text-right">
                        <a href="/politique-confidentialite" className="font-size-20 font-nhaas45 text-secondary px-0 py-0 py-md-3 nav-link" role="button">Politique de confidentialité</a>
                        <a href="/informations-légales" className="font-size-20 font-nhaas45 text-secondary px-0 py-0 py-md-3 nav-link" role="button">Informations légales</a>
                        <a href="/contact-pitchichi" className="font-size-20 font-nhaas45 text-secondary px-0 py-0 py-md-3 nav-link" role="button">Contact</a>
                    </Col>
                    <Col className="d-flex flex-column align-items-end justify-content-start justify-content-md-end px-0 py-3 py-md-0 text-right">
                        <div className="p-0 p-md-3 text-md-right line-height-12">
                            Pitchichi<br></br>
                            75 rue d'Amsterdam<br></br>
                            75008 Paris<br></br>
                            <a className="text-secondary" href="tel:+33952632488">09.52.63.24.88</a>
                        </div>
                        <div className="p-0 p-md-3 text-md-right">
                            Copyright © 2022 Pitchichi. Tous droits réservés

                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}