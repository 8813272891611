import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Politics() {
    return (
        <>
            <Container className="text-primary pt-5">
                <Row className="text-primary pt-5">
                    <Col className="text-primary pt-5">
                        <div className="font-size-35 font-recoletamedium">POLITIQUE DE CONFIDENTIALITE</div>
                        <br></br>
                        <span className="font-recoletasemibold"></span>
                        <div className="font-nhaas45 font-size-25">
                            La présente Politique de confidentialité a pour vocation d’informer les personnes (Ci-après les « <span className="font-recoletasemibold">Utilisateurs </span> ») utilisant le site <span className="font-recoletasemibold">https://www.pitchichi.com/</span> (le « <span className="font-recoletasemibold">Site</span> ») de la manière dont <span className="font-recoletasemibold">PASSING-SHOT</span> collecte, utilise et partage les données personnelles, à savoir toute information relative à une personne physique identifiée ou qui peut être identifiée, directement ou indirectement (Ci-après les « <span className="font-recoletasemibold">Données personnelles</span> ») via le Site.
                        </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                            Cette Politique s’applique à toutes les informations fournies par vous-même, ou recueillies par nous lors de votre navigation sur le Site, conformément à la réglementation en vigueur en France en matière de données personnelles telle qu’issue de la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, dite loi « Informatique et Libertés » et du Règlement UE 2016/679 du 27 avril 2016 sur la protection des données dit « <span className="font-recoletasemibold">RGPD</span> ».
                        </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                            Nous nous réservons la possibilité de modifier la présente Politique de Confidentialité. En naviguant sur le Site, vous êtes réputé avoir lu et accepté cette Politique de confidentialité, dans sa version en vigueur.
                        </div>
                        <br></br>
                        <div className="font-size-35 font-recoletamedium">1. Qui est responsable de vos données personnelles ? </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                            Le responsable de traitement, qui collecte et gère vos données, est la société PASSING-SHOT, société par actions simplifiée, au capital social de 30.612,20 €‬ immatriculée au Registre du commerce et des sociétés de Nanterre sous le n°840 030 100, dont le siège social est 10 D rue Alexandre Coutureau 92210 Saint-Cloud, représentée par son Président Monsieur Thomas SABATIER.
                        </div>
                        <br></br>
                        <div className="font-size-35 font-recoletamedium">2. Quelles données personnelles sont collectées ?  </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                            Constitue une donnée personnelle toute information relative à une personne physique identifiée ou qui peut être identifiée, directement ou indirectement (Ci-après les « <span className="font-recoletasemibold">Données personnelles</span> »).
                        </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                            Lors de votre navigation sur le Site, nous pouvons collecter les catégories de données suivantes :
                            <ul>
                                <li>
                                    Nom, Prénom
                                </li>
                                <li>
                                    Fonction/profession
                                </li>
                                <li>
                                    Adresse Email
                                </li>
                                <li>
                                    Adresse IP
                                </li>
                                <li>
                                    Téléphone
                                </li>
                                <li>
                                    Informations stockées sur votre terminal : Cookies. Pour plus de détails, consultez notre Politique de cookies.
                                </li>
                            </ul>
                        </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                            Nous pouvons également collecter toute Donnée personnelle que vous êtes susceptible de nous fournir lors de nos échanges si vous nous contactez directement par email, téléphone ou par voie postale. A ce titre, vous vous engagez à fournir des Données personnelles d’identification actualisées et valides, et garantissez ne faire aucune fausse déclaration et ne fournir aucune information erronée.
                        </div>
                        <br></br>
                        <div className="font-size-35 font-recoletamedium">3. Comment vos Données personnelles sont-elles collectées ? </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                            PASSING-SHOT collecte vos Données personnelles :
                            <ul>
                                <li>
                                    lorsque vous renseignez le formulaire de contact pour les fins suivantes : (i) demande d’information sur le cabinet de conseil, (ii) utilisation des services du cabinet, (iii) nous envoyer des remarques, (iv) travailler pour notre cabinet, (v) juste pour échanger,
                                </li>
                                <li>
                                    lorsque vous renseignez le formulaire de coordonnées vous permettant de recevoir une recommandation ou une sélection d’agences,
                                </li>
                                <li>
                                    lorsque vous nous confiez une mission,
                                </li>
                                <li>
                                    lorsque vous nous demandez l’élaboration de votre « brief »,
                                </li>
                                <li>
                                    lorsque vous nous demandez votre « long list » d’agences,
                                </li>
                                <li>
                                    lorsque vous souhaitez lancer une compétition d’agences,
                                </li>
                                <li>
                                    lorsque vous nous contactez, par email, par téléphone, ou par voie postale.
                                </li>
                                <li>
                                    lorsque vous nous contactez via les réseaux sociaux Facebook, LinkedIn, Instagram
                                </li>
                            </ul>
                        </div>
                        <br></br>
                        <div className="font-size-35 font-recoletamedium">4. Pour quelles finalités vos Données personnelles sont-elles collectées ? </div>
                        <div className="font-size-35 font-recoletamedium">4.1. Généralités</div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                            Les Données personnelles obligatoires collectées sur le Site sont les données strictement nécessaires au traitement de vos demandes. Le caractère obligatoire des informations demandées vous est signalé lors de la collecte par la présence d’un astérisque.
                        </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                            Les autres Données personnelles collectées sont facultatives.
                        </div>
                        <br></br>
                        <div className="font-size-35 font-recoletamedium">4.2. Finalités et base légale du traitement</div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                            Vos Données personnelles sont collectées et traitées pour permettre :
                            <ul>
                                <li>
                                    (i)	La gestion et le traitement de vos demandes de renseignements,
                                </li>
                                <li>
                                    (ii)	L’accomplissement de notre mission de conseil et d’expertise dans la sélection d’agences correspondant à votre projet,
                                </li>
                                <li>
                                    (iii)	L’accomplissement de notre mission d’accompagnement et de mise en relation avec les agences sélectionnées pour la réalisation de votre projet,
                                </li>
                                <li>
                                    (iv)	Nos opérations de prospection dans le cadre de notre activité de cabinet de conseil pour les professionnels annonceurs et agences.
                                </li>

                            </ul>
                        </div>
                        <br></br>
                        <div className="font-size-35 font-recoletamedium">5. Qui a accès à vos Données personnelles ?  </div>
                        <div className="font-size-35 font-recoletamedium">5.1. Le personnel de PASSING-SHOT </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                            Vos Données personnelles sont destinées aux services et personnes dûment habilitées à les traiter au sein de PASSING-SHOT en fonction de leurs attributions à savoir direction, équipe opérationnelle et service commercial ainsi qu’à ses éventuels sous-traitants.
                        </div>
                        <br></br>
                        <div className="font-size-35 font-recoletamedium">5.2. Les sous-traitants de PASSING-SHOT </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                            Dans le cadre de l’exercice de ses activités et de la fourniture de ses services, PASSING-SHOT a recours à des sous-traitants. Ceux-ci :
                            <ul>
                                <li>
                                    a)	traitent vos Données personnelles pour son compte, et sur ses instructions,
                                </li>
                                <li>
                                    b)	présentent des garanties suffisantes quant à la mise en œuvre des mesures techniques et organisationnelles appropriées, afin d’assurer la sécurité et la confidentialité de vos données.
                                </li>


                            </ul>
                        </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                            PASSING-SHOT a recours à des sous-traitants situés au sein de l’Union européenne, offrant ainsi un niveau de protection adéquat.
                        </div>
                        <br></br>
                        <div className="font-size-35 font-recoletamedium">5.3. Autres destinataires</div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                            PASSING-SHOT peut également être amené à communiquer vos Données personnelles aux autorités qu’elles soient judiciaires ou administratives afin de se conformer à une exigence légale ou réglementaire, à une assignation judiciaire, un mandat, jugement ou ordonnance, ou si cette divulgation est nécessaire à une autorité qui a fait valoir de son autorité légitime d’obtenir les informations dans le cadre d’une mission d’enquête, ou d’une procédure, sur le territoire national ou à l’étranger.
                        </div>
                        <br></br>
                        <div className="font-size-35 font-recoletamedium">6. Combien de temps vos Données personnelles sont-elles conservées ? </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                            PASSING-SHOT conserve vos Données personnelles le temps strictement nécessaire à l’accomplissement des finalités pour lesquelles elles sont collectées et traitées.
                        </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                            Afin de déterminer la durée de conservation de vos Données personnelles, nous faisons application des critères suivants :
                            <ul>
                                <li>
                                    Le temps nécessaire pour traiter votre demande ou votre réclamation,
                                </li>
                                <li>
                                    La nécessité de conserver un historique de vos interactions avec nous pour la bonne gestion de notre relation commerciale.
                                </li>
                            </ul>
                        </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                            Les Données personnelles collectées dans le cadre des formulaires de contact et de coordonnées sont conservées pendant un délai de 3 ans à compter de la collecte initiale ou du dernier contact émanant de votre part.
                        </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                            Au-delà de cette durée, vos Données personnelles peuvent également faire l’objet d’un archivage impliquant un accès encadré, limité et justifié, le temps nécessaire (i) au respect des obligations légales et réglementaires de PASSING-SHOT, et/ou (ii) pour lui permettre de faire valoir un droit en justice, et ce avant d’être définitivement supprimées.
                        </div>
                        <br></br>
                        <div className="font-size-35 font-recoletamedium">7. Comment PASSING-SHOT assure-t-elle la sécurité et la confidentialité de vos données personnelles ? </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                            PASSING-SHOT s’engage à traiter vos Données personnelles de manière :
                            <ul>
                                <li>
                                    c)	licite,
                                </li>
                                <li>
                                    d)	loyale,
                                </li>
                                <li>
                                    e)	transparente,
                                </li>
                                <li>
                                    f)	proportionnée,
                                </li>
                                <li>
                                    g)	pertinente,
                                </li>
                                <li>
                                    h)	dans le strict cadre des finalités poursuivies et annoncées,
                                </li>
                                <li>
                                    i)	pour la durée nécessaire aux traitements mis en place,
                                </li>
                                <li>
                                    j)	de façon sécurisée.
                                </li>
                            </ul>
                        </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                        PASSING-SHOT met en place et actualise les mesures techniques et organisationnelles appropriées pour assurer la sécurité et la confidentialité de vos Données personnelles en empêchant qu’elles soient déformées, endommagées ou communiquées à des tiers non autorisés.
                        </div>
                        <br></br>
                        <div className="font-size-35 font-recoletamedium">8. Quels sont vos droits sur vos Données personnelles ? </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                        Conformément aux articles 15 à 21 du RGPD, vous disposez des droits suivants :
                            <ul>
                                <li>
                                a)	droit d’accès, 
                                </li>
                                <li>
                                b)	droit de rectification, 
                                </li>
                                <li>
                                c)	droit d’effacement, 
                                </li>
                                <li>
                                d)	droit de limitation,
                                </li>
                                <li>
                                e)	droit à la portabilité des données,
                                </li>
                                <li>
                                f)	droit d’opposition.
                                </li>
                                
                            </ul>
                        </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                        Votre demande doit être effectuée par écrit, à l’adresse électronique dédiée de PASSING-SHOT, à savoir : contact@pitchichi.com.
                        </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                        Vous avez également le droit, à tout moment, de <span className="font-recoletasemibold">retirer votre consentement</span> aux traitements pour lesquels celui-ci a été recueilli.
                        </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                        PASSING-SHOT fournira à la personne qui exerce l’un de ces droits des informations sur les mesures prises, dans les meilleurs délais et en tout état de cause dans un délai d’un (1) mois à compter de la réception de la demande. Ce délai peut être prolongé de deux (2) mois eu égard à la complexité et au nombre de demandes. 
                        </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                        Si PASSING-SHOT ne donne pas suite à votre demande, elle vous en informera, dans les meilleurs délais, et au plus tard dans un délai d’un (1) mois à compter de la réception de votre demande en vous précisant les motifs de son inaction et de la possibilité d’introduire une réclamation auprès d’une autorité de contrôle et de former un recours juridictionnel.
                        </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                        L’exercice de ces droits est gratuit. Toutefois, en cas de demande manifestement infondée ou excessive, PASSING-SHOT se réserve la possibilité (i) d’exiger le paiement de frais tenant compte des coûts administratifs ou (ii) de refuser de donner suite à ces demandes.
                        </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                        En cas de désaccord persistant concernant vos Données personnelles, vous avez le droit de saisir la CNIL à l’adresse suivante : Commission Nationale de l'Informatique et des Libertés : 3 Place de Fontenoy - TSA 80715 - 75334 Paris Cedex 07.
                        </div>
                        <br></br>
                        <div className="font-size-35 font-recoletamedium">9. Quels recours en cas de violation de vos données ?  </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                        En cas de violation de vos Données personnelles susceptible d’engendrer un risque pour vos droits et libertés, PASSING-SHOT en notifie la violation à la CNIL dans les meilleurs délais, et, si possible 72 heures au plus tard après en avoir pris connaissance. Lorsqu’une violation de vos Données personnelles est susceptible d’engendrer un risque élevé pour les droits et libertés d’un Utilisateur, PASSING-SHOT en informe l’Utilisateur, dans les meilleurs délais, sous réserve des exceptions prévues à l’article 34 du RGPD. 
                        </div>
                        <br></br>
                        <div className="font-size-35 font-recoletamedium">10. Catégories de données particulières</div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25">
                        Le Site ayant vocation à s’adresser à des professionnels, PASSING-SHOT n’a pas vocation à recueillir les Données personnelles de consommateurs, ni des informations relatives à des personnes mineures. 
                        </div>
                        <br></br>
                        <div className="font-nhaas45 font-size-25 pb-5">
                        PASSING-SHOT n’a pas non plus vocation à collecter des informations dites sensibles telles que l’origine raciale ou ethnique, l’orientation sexuelle, l’état de santé, les opinions politiques, les convictions religieuses, philosophiques ou l’appartenance syndicale, ou encore les données génétiques ou biométriques.
                        </div>
                        <br></br>
                    </Col>
                </Row>
            </Container>

        </>
    );
}

