import "../../../App.scss";
import React, { useState, useEffect } from "react";
import axios from 'axios'
import { TESTIMONIALS_URL } from "../../../util/Constant";
import "../../../components/Carousel.scss";

import "./SectionH.scss";
import disableScroll from 'disable-scroll';

import Carousel, {
    arrowsPlugin,
    slidesToShowPlugin,
    infinitePlugin,
    Dots
} from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import Carouselcomponent from "../../../components/Carousel"
import arrowLeft from "../../../assets/carousel-arrow-left.svg";
import arrowRIght from "../../../assets/carousel-arrow-right.svg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

export default function SectionH(props) {
    const [displayTestimonials, setdisplayTestimonials] = useState(false)

    const [Slides, setSlides] = useState([])

    const getTestimonials = () => {
        axios.get(TESTIMONIALS_URL)
            .then((response) => {
                const filtered = response?.data?.filter(value => value.displayed_on_platform_page === true)
                const slides = filtered.map(p => {
                    return <div className="d-flex flex-column justify-content-center align-items-start px-3 px-sm-3 px-lg-4 px-xl-5 text-left sectionh-card bg-blue text-primary font-nhaas45 font-size-20 line-height-11 mx-1 testimonal-bold">
                        <div dangerouslySetInnerHTML={{ __html: `${p.quote}` }}>
                        </div>
                        <br></br>
                        <div className="font-recoletamedium">
                            {p.author}
                        </div>
                    </div>
                })
                setSlides(slides)

                setdisplayTestimonials(true)
            })
            .catch(error => console.error(error))
    }

    useEffect(() => {
        getTestimonials();
    }, []);

    const [value, setValue] = useState(0)

    onchange = (value) => {
        setValue(value);
    }
    const onTouchStart = (e) => {
        disableScroll.on();

    }

    const onTouchEnd = (e) => {
        disableScroll.off();

    }





    return (
        <Container
            fluid
            className="home-section-h bg-secondary py-5 fade-in-slow"
        >
            <Row className="d-flex flex-column text-center justify-content-center align-items-center text-primary mb-3 mb-md-5">
                <div className="line-height-1">
                    <span className="font-size-55 font-nhaas65 text-uppercase">
                        pitchichi{" "}
                    </span>
                    <br className="d-sm-none d-block"></br>
                    <span className="font-size-55 font-recoletalight text-uppercase">
                        en quelques mots
                    </span>

                </div>
                <div className="mt-2">
                    <span className="font-size-35 font-nhaas45 ">
                        Témoignages de nos clients
                    </span>
                </div>
            </Row>
            {displayTestimonials && <div className={`d-flex align-items-center mx-auto carousel flex-column justify-content-center ${props?.carouselHeight}`} onTouchStart={onTouchStart} onTouchEnd={onTouchEnd}>
                <Carousel
                    value={value}
                    onChange={onchange}
                    slides={Slides}
                    plugins={[
                        'fastSwipe',
                        {
                            resolve: arrowsPlugin,
                            options: {
                                arrowLeft: (
                                    <img src={arrowLeft} alt="" className="arrow" />
                                ),
                                arrowLeftDisabled: (
                                    <img src={arrowLeft} alt="" className="arrow" />
                                ),
                                arrowRight: (
                                    <img src={arrowRIght} alt="" className="arrow" />
                                ),
                                arrowRightDisabled: (
                                    <img src={arrowRIght} alt="" className="arrow" />
                                ),
                                addArrowClickHandler: true,
                            },
                        },
                        {
                            resolve: slidesToShowPlugin,
                            options: {
                                numberOfSlides: 3,
                            },
                        },
                        {
                            resolve: infinitePlugin,
                            options: {
                                numberOfInfiniteClones: 1,
                            },
                        },
                        /* {
                            resolve: autoplayPlugin,
                            options: {
                                interval: 500,
                            }
                        }, */
                    ]}

                    breakpoints={{
                        767: {
                            plugins: [

                                {
                                    resolve: slidesToShowPlugin,
                                    options: {
                                        numberOfSlides: 2,

                                    }
                                },
                                {
                                    resolve: infinitePlugin,
                                    options: {
                                        numberOfInfiniteClones: 1,
                                    },
                                },

                            ]
                        },
                        570: {
                            plugins: [

                                {
                                    resolve: slidesToShowPlugin,
                                    options: {
                                        numberOfSlides: 1,

                                    }
                                },
                                {
                                    resolve: infinitePlugin,
                                    options: {
                                        numberOfInfiniteClones: 1,
                                    },
                                },

                            ]
                        }
                    }}
                >
                </Carousel>
                <Dots value={value} onChange={onchange} number={Slides?.length} className="d-flex d-md-none" />
            </div>}
        </Container >

    )
}
