import "../../App.scss";
import "./Agenciescategory.scss";

import React, { useState, useEffect, createRef } from "react";
import axios from 'axios'
import { AGENCIES_URL } from "../../util/Constant";
import { Helmet } from "react-helmet";

import { LinkContainer } from 'react-router-bootstrap'
import card1 from "./../../assets/illustrations/main/card1.png.webp";

import Container from "react-bootstrap/Container";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import campaign from "./../../assets/illustrations/campaign.svg";
import branding from "./../../assets/illustrations/branding.svg";
import social from "./../../assets/illustrations/social.svg";
import luxe from "./../../assets/illustrations/luxe.svg";
import experentiel from "./../../assets/illustrations/experentiel.svg";
import digital from "./../../assets/illustrations/digital.svg";

export default function Agenciescategory(props) {
    const [agencies, setAgencies] = useState('')
    const [nextPageUrl, setNextPageUrl] = useState(null)
    const [previousPageUrl, setPreviousPageUrl] = useState(null)
    const myRef = createRef()
    const startRef = createRef()

    const getAgencies = () => {
        axios.get(AGENCIES_URL, { params: { characteristic: props.match.params.slug } })
            .then((response) => {
                setAgencies(response.data)
                setNextPageUrl(response.data.next)
                setPreviousPageUrl(response.data.previous)
            })
            .catch(error => console.error(error))
    }

    const getAgenciesNext = (url) => {
        axios.get(url)
            .then((response) => {
                setAgencies(response.data)
                setNextPageUrl(response.data.next)
                setPreviousPageUrl(response.data.previous)

            })
            .catch(error => console.error(error))
    }

    const getAgenciesPrevious = (url) => {
        axios.get(url)
            .then((response) => {
                setAgencies(response.data)
                setNextPageUrl(response.data.next)
                setPreviousPageUrl(response.data.previous)

            })
            .catch(error => console.error(error))
    }

    function imageBanner() {
        switch (props.match.params.slug) {
            case "Branding":
                return branding
            case "Digital":
                return digital
            case "Publicité":
                return campaign
            case "Luxe":
                return luxe
            case "Social media":
                return social
            case "Expérientiel":
                return experentiel
            default:
                return campaign
        }
    }

    function titleBanner() {
        switch (props.match.params.slug) {
            case "Branding":
                return "Agences de"
            case "Digital":
                return "Agences"
            case "Publicité":
                return "Agences de"
            case "Luxe":
                return "Agences"
            case "Social media":
                return "Agences"
            case "Expérientiel":
                return "Agences"
            default:
                return "Agences de"
        }
    }

    function subTitleBanner() {
        switch (props.match.params.slug) {
            case "Branding":
                return "Branding"
            case "Digital":
                return "Digitales"
            case "Publicité":
                return "Publicité"
            case "Luxe":
                return "Luxe"
            case "Social media":
                return "Social media"
            case "Expérientiel":
                return "Expérientielles"
            default:
                return "Communication"
        }
    }

    function subTitle3Banner() {
        switch (props.match.params.slug) {
            case "Branding":
                return "Liste des agences de Branding / Design"
            case "Digital":
                return "Liste des agences Digitales"
            case "Publicité":
                return "Liste des agences de Publicité"
            case "Luxe":
                return "Liste des agences Luxe"
            case "Social media":
                return "Liste des agences Social Media / Influenceurs"
            case "Expérientiel":
                return "Liste des agences Expérientielles / Événementielles"
            case "Ouest":
            case "Est":
                return `Liste des agences de l'${props.match.params.slug}`
            case "Sud-Ouest":
            case "Nord":
            case "Centre":
            case "Sud-Est":
                return `Liste des agences du ${props.match.params.slug}`
            default:
                return `Liste des principales agences à ${props.match.params.slug}`
        }
    }

    function returnMeta() {
        switch (props.match.params.slug) {
            case "Branding":
                return "Trouvez la meilleure agence de branding à Paris et dans toute la France"
            case "Digital":
                return "Trouvez la meilleure agence de digital à Paris et dans toute la France"
            case "Publicité":
                return "Trouvez la meilleure agence de publicité à Paris et dans toute la France"
            case "Luxe":
                return "Trouvez la meilleure agence de luxe à Paris et dans toute la France"
            case "Social media":
                return "Trouvez la meilleure agence de social media à Paris et dans toute la France"
            case "Expérientiel":
                return "Trouvez la meilleure agence d’expérientiel à Paris et dans toute la France"
            case "Ouest":
            case "Est":
                return `Trouvez la meilleure agence de communication dans l’${props.match.params.slug} de la France`
            case "Sud-Ouest":
            case "Nord":
            case "Centre":
            case "Sud-Est":
                return `Trouvez la meilleure agence de communication dans le ${props.match.params.slug} de la France`
            default:
                return "Trouvez la meilleure agence de publicité en France"
        }
    }

    useEffect(() => {
        getAgencies();
    }, []);

    useEffect(() => {
        if (previousPageUrl !== null) {
            myRef?.current.scrollIntoView({ behavior: 'smooth' })
        } else if (previousPageUrl === null && nextPageUrl !== null) {
            startRef?.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [nextPageUrl, previousPageUrl]);

    return (
        <>
            <Helmet>
                <title>Guide Pitchichi | Liste des meilleures agences de communication - {props.match.params.slug} | Trouvez l’agence parfaite (branding, digital, publicité, social media, luxe) 
                </title>
                <meta name="description" content={returnMeta()} />
                <link rel="pitchichi" href="https://www.pitchichi.com/guide-meilleures-agences-pub-com" />
            </Helmet>
            <Container ref={startRef} fluid className="agencies-category bg-blue fade-in-fast text-primary d-flex flex-column justify-content-center wmax-2160">

                <Row className="section-1 align-items-center flex-column flex-md-row">
                    <Col className="d-flex justify-content-center justify-content-md-end px-0 pr-md-5">
                        <Image src={imageBanner()} fluid className="agencies-section-1-img pr-0 pr-md-5 h-100" />
                    </Col>
                    <Col className="mt-3 mt-md-0 p-0">
                        <Row>
                            <Col className="font-size-90 line-height-sm text-center text-md-left text-uppercase mb-3 mb-sm-0">
                                <div className="font-recoletalight">{titleBanner()}</div>
                                <div className=""><span className="font-nhaas65">{subTitleBanner()}</span></div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div ref={myRef} className="font-size-35 text-center text-md-left font-nhaas45 mt-0 mt-sm-3 mb-4 line-height-1">Pitchichi, l’expert de la recommandation d’agences</div>
                                <div className="text-center text-md-left">
                                    <LinkContainer to="/wizard">
                                        <Button variant="yellow" className="btn-pitchichi-primary">Trouver mon agence</Button>
                                    </LinkContainer>
                                </div>

                            </Col>
                        </Row>
                    </Col>
                </Row>


            </Container>
            <div className="bg-secondary">
                <Container fluid className="agencies-category-section-2 bg-secondary justify-content-center d-flex flex-column fade-in-slow wmax-2160">
                    <Row className="text-center line-height-1">
                        <Col className="font-size-35 font-recoletasemibold align-items-center justify-content-center d-flex flex-column text-primary mb-5">
                            <div className="">
                                <span className="">
                                    {subTitle3Banner()}
                                </span>
                            </div>

                        </Col>
                    </Row>
                    <Row xs={2} md={4} className="text-primary agencies-card-container fade-in-slow">
                        {agencies && agencies?.results.map((agency) => (

                            <div className="fade-in-slow clickable">
                                <LinkContainer to={`${agency?.slug}`}>
                                    <a href="/" className="a-link" >
                                        <Card className="card-agency">
                                            <Card.Header style={{ background: agency?.team_picture?.file !== undefined ? `url(${agency?.team_picture?.file})` : "#d0e4fc" }}>
                                                <div className="card-agency-logo" style={{ backgroundImage: `url(${agency?.logo?.file})` }}></div>
                                            </Card.Header>
                                            <Card.Body className="line-height-1 pt-4 pl-3 pb-3 pt-md-5">
                                                <div className="font-size-35 font-NeueHaasDisplayMedium">{agency?.name}</div>
                                                <div className="font-size-30 font-nhaas45 py-1">{agency?.city}
                                                    {agency?.secondary_cities?.map((sc) => (
                                                        <span> - {sc}</span>
                                                    ))}
                                                </div>
                                                {agency.holding_name
                                                    ? <div className="font-size-20 font-NeueHaasDisplayMedium text-uppercase">{agency?.holding_name}</div>
                                                    : <div className="font-size-20 font-NeueHaasDisplayMedium text-uppercase">Indépendant</div>
                                                }
                                                <div className="font-size-20 font-NeueHaasDisplayMedium">{agency?.effectif_total} pers.</div>

                                            </Card.Body>

                                        </Card>
                                    </a>
                                </LinkContainer>
                            </div>
                        ))}


                    </Row>
                    <Row className="justify-content-center mb-4">

                        <Button className="btn-pitchichi-previous m-3" disabled={!previousPageUrl} onClick={() => getAgenciesPrevious(previousPageUrl)}>Page précédente</Button>

                        <Button className="btn-pitchichi-next m-3" disabled={!nextPageUrl} onClick={() => getAgenciesNext(nextPageUrl)}>Page suivante</Button>
                    </Row>
                </Container>
            </div>

            <Container fluid className="guide guide-section-choice bg-secondary justify-content-center d-flex flex-column fade-in-slow ">
                <Row className="text-center line-height-1 ">
                    <Col className="align-items-center justify-content-center d-flex flex-column text-primary">
                        <div className="guide-section-logo">
                            <img src={card1} alt=""></img>
                        </div>
                        <div className="mt-md-5 mt-3">
                            <span className="font-size-35 font-nhaas45">
                                Difficile de faire son choix ?
                            </span>
                        </div>
                        <div className="mb-md-5 mb-3 d-none d-sm-block">
                            <span className="font-size-35 font-nhaas45">
                                Pitchichi vous propose sa sélection d’agences pour votre projet.
                            </span>
                        </div>
                        <div className="mb-md-5 mb-3 d-block d-sm-none">
                            <div className="font-size-35 font-nhaas45">
                                Pitchichi vous propose sa sélection d’agences
                            </div>
                            <div className="font-size-35 font-nhaas45">
                                pour votre projet.
                            </div>
                        </div>

                        <LinkContainer to="/wizard">
                            <Button variant="yellow" className="btn-pitchichi-primary">Recevoir ma sélection gratuite</Button>
                        </LinkContainer>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
