import "../../App.scss";
import "./Firm.scss";
import React from "react";
import { Helmet } from "react-helmet";
import { LinkContainer } from 'react-router-bootstrap'
import SectionGfirm from "../Home/Sections/SectionGfirm";
import SectionI from "./SectionI";
import SectionB from "./SectionB";
import SectionH from "./SectionH";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import firm1 from "./../../assets/illustrations/firm/Asset2.svg";
import firm2 from "./../../assets/illustrations/firm/firm-2.svg";
import leftTree from "./../../assets/illustrations/firm/firm-tree-left.svg";
import rightTree from "./../../assets/illustrations/firm/firm-tree-right.svg";


import choice2 from "./../../assets/illustrations/firm/Asset1.svg";
import choice3 from "./../../assets/illustrations/firm/Asset3.svg";
import choice4 from "./../../assets/illustrations/firm/Asset4.svg";
import choice5 from "./../../assets/illustrations/firm/Asset5.svg";
import illustrationButton from "./../../assets/illustrations/firm/logo-button.svg";

import agencies from "./../../assets/backgrounds/firmsectioncompete.svg";
import listingMobile from "./../../assets/backgrounds/firmsectioncompete-mobile.svg";

import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import SectionC from "./SectionC";

export default function Firm() {


    return (
        <>
            <Helmet>
                <title>Pitchichi | Cabinet de conseil en choix d'agences & aventures créatives | Compétitions | Appel d’offres | Pitch | Consultation | Consulting |
                </title>
                <meta name="description" content="Trouvez l’agence de publicité en accord avec vos problématiques de communication, vos ambitions et votre budget grâce à Pitchichi, l’expert en choix d’agences." />
                <link rel="pitchichi" href="https://www.pitchichi.com/competition-agence-communication" />
            </Helmet>
            <div className="firm">
                <Container fluid>
                    <Row className="vh-100 d-flex align-items-center bg-purple main-section-firm fade-in-fast hmax-1440">
                        <Col className="d-flex flex-column align-items-center text-secondary fs-1 mt-n5">
                            <div className="font-size-90 text-uppercase line-height-09 text-center"><span className="font-nhaas65">conseil</span>{" "}<span className="font-recoletalight">en choix</span></div>
                            <div className="font-size-90 text-uppercase font-recoletalight line-height-09 text-center">d’agences ET</div>
                            <div className="font-size-90 text-uppercase font-recoletalight line-height-09 text-center">aventures créatives</div>

                            <div className="text-center sub-title mt-4 d-none d-sm-block">
                                <div className="font-size-35 font-nhaas45 line-height-1">Pitchichi organise vos compétitions d’agences</div>
                                <div className="font-size-35 font-nhaas45 line-height-1">et vous guide à chaque étape vers les sommets.</div>
                            </div>
                            <div className="text-center sub-title mt-4 d-block d-sm-none">
                                <div className="font-size-35 font-nhaas45 line-height-1">Pitchichi organise vos compétitions d’agences et vous guide à chaque étape vers les sommets.</div>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container fluid className="firm section-2 bg-blue justify-content-center align-items-center text-primary d-none d-sm-flex">
                    <Row className="flex-column-reverse flex-md-row w-100">
                        <Col className="d-flex flex-column justify-content-between">
                            <Row className="flex-column font-size-35 align-items-center text-center text-md-right align-items-md-end line-height-1 ">
                                <div>
                                    <span className="font-recoletasemibold">Trouver la bonne agence</span>{" "}<span className="font-nhaas45">de communication,</span>
                                </div>
                                <div>
                                    <span className="font-nhaas45">celle qui positionnera parfaitement votre marque</span>
                                </div>
                                <div>
                                    <span className="font-nhaas45">et lui donnera vie avec élégance et impact,</span>
                                </div>
                                <div>
                                    <span className="font-nhaas45">est un graal pour tout annonceur.</span>
                                </div>
                                <div>
                                    <br></br>
                                </div>

                                <div>
                                    <span className="font-nhaas45">Si organiser une compétition d’agences</span>
                                </div>
                                <div>
                                    <span className="font-nhaas45">n’est pas obligatoire, ça reste le meilleur moyen</span>
                                </div>
                                <div>
                                    <span className="font-nhaas45">d’explorer de </span>{" "}<span className="font-recoletasemibold">nouveaux territoires.</span>
                                </div>

                            </Row>

                        </Col>
                        <Col className="d-flex justify-content-center align-items-start justify-content-md-start pb-3 pb-md-0 pl-md-5">
                            <img src={firm1} alt="pitchichi" className="img-1 ml-0 ml-md-5" />
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="firm section-2 bg-blue justify-content-center align-items-center text-primary d-flex d-sm-none px-3">
                    <Row className="flex-column-reverse flex-md-row w-100">
                        <Col className="d-flex flex-column justify-content-between">
                            <Row className="flex-column font-size-35 align-items-center text-center text-md-right align-items-md-end line-height-1 w-100 m-auto">
                                <div>
                                    <span className="font-recoletasemibold">Trouver la bonne agence</span>{" "}<span className="font-nhaas45">de communication, </span>

                                    <span className="font-nhaas45">celle qui positionnera parfaitement votre marque </span>

                                    <span className="font-nhaas45">et lui donnera vie avec élégance et impact, </span>

                                    <span className="font-nhaas45">est un graal pour tout annonceur.</span>

                                    <br></br>
                                    <br></br>

                                    <span className="font-nhaas45">Si organiser une compétition d’agences </span>

                                    <span className="font-nhaas45">n’est pas obligatoire, ça reste le meilleur moyen </span>

                                    <span className="font-nhaas45">d’explorer de </span>{" "}<span className="font-recoletasemibold">nouveaux territoires.</span>
                                </div>

                            </Row>

                        </Col>
                        <Col className="d-flex justify-content-center align-items-start justify-content-md-start pb-3 pb-md-0 pl-md-5">
                            <img src={firm1} alt="pitchichi" className="img-1 ml-0 ml-md-5" />
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="firm section-2 bg-blue  justify-content-center align-items-center mt-md-3 py-md-5 text-primary d-none d-sm-flex">
                    <Row className="flex-column flex-md-row w-100 mt-md-5 py-md-5 ">
                        <Col className="d-flex justify-content-center justify-content-md-end pb-3 pb-md-0 pr-md-5  align-items-start">
                            <img src={firm2} alt="pitchichi" className="img-2 mr-0 mr-md-5" />
                        </Col>
                        <Col className="d-flex flex-column justify-content-between">
                            <Row className="flex-column font-size-35 align-items-center text-center text-md-left align-items-md-start line-height-1 mt-2">
                                <div>
                                    <span className="font-nhaas45">Un pitch est une</span>{" "}
                                    <span className="font-recoletasemibold">véritable quête</span>{" "}<span className="font-nhaas45">qui vous </span>
                                </div>
                                <div>
                                    <span className="font-nhaas45">{" "}mènera au fil des rencontres et des échanges,</span>
                                </div>
                                <div>
                                    <span className="font-nhaas45">là où aucune autre marque n’a encore jamais été,</span>
                                </div>
                                <div>
                                    <span className="font-recoletasemibold">avec sa part de risque</span>{" "}<span className="font-nhaas45">et de subjectivité.</span>
                                </div>

                                <div>
                                    <br></br>
                                </div>
                                <div>
                                    <span className="font-nhaas45">Avec Pitchichi, nous vous accompagnons</span>
                                </div>
                                <div>
                                    <span className="font-recoletasemibold">à chaque étape</span>{" "}<span className="font-nhaas45">de votre appel d’offres pour vous</span>
                                </div>
                                <div>
                                    <span className="font-nhaas45">permettre de vivre sereinement ce moment-clé</span>
                                </div>
                                <div>
                                    <span className="font-nhaas45">de votre entreprise et</span>{" "}<span className="font-recoletasemibold">profiter pleinement</span>
                                </div>
                                <div>
                                    <span className="font-nhaas45">de votre aventure créative !</span>
                                </div>

                            </Row>

                        </Col>

                    </Row>
                </Container>
                <Container fluid className="firm section-2 bg-blue  justify-content-center align-items-center mt-md-3 py-md-5 text-primary d-flex d-sm-none">
                    <Row className="flex-column flex-md-row w-100 mt-md-5 py-md-5 ">
                        <Col className="d-flex justify-content-center justify-content-md-end pb-3 pb-md-0 pr-md-5  align-items-start">
                            <img src={firm2} alt="pitchichi" className="img-2 mr-0 mr-md-5" />
                        </Col>
                        <Col className="d-flex flex-column justify-content-between">
                            <Row className="flex-column font-size-35 align-items-center text-center text-md-left align-items-md-start line-height-1 mt-2 w-100 m-auto">
                                <div>
                                    <span className="font-nhaas45">Un pitch est une</span>{" "}
                                    <span className="font-recoletasemibold">véritable quête</span>{" "}<span className="font-nhaas45">qui vous </span>

                                    <span className="font-nhaas45"> mènera au fil des rencontres et des échanges, </span>

                                    <span className="font-nhaas45">là où aucune autre marque n’a encore jamais été, </span>

                                    <span className="font-recoletasemibold">avec sa part de risque</span>{" "}<span className="font-nhaas45">et de subjectivité.</span>

                                    <br></br>
                                    <br></br>

                                    <span className="font-nhaas45">Avec Pitchichi, nous vous accompagnons </span>

                                    <span className="font-recoletasemibold">à chaque étape</span>{" "}<span className="font-nhaas45">de votre appel d’offres pour vous </span>

                                    <span className="font-nhaas45">permettre de vivre sereinement ce moment-clé </span>

                                    <span className="font-nhaas45">de votre entreprise et</span>{" "}<span className="font-recoletasemibold">profiter pleinement </span>

                                    <span className="font-nhaas45">de votre aventure créative !</span>
                                </div>

                            </Row>

                        </Col>

                    </Row>
                </Container>
                <Container fluid className="firm section-2 bg-blue d-flex justify-content-center align-items-center mb-5">
                    <Row className="flex-column flex-md-row">

                        <LinkContainer to="/contact-pitchichi">

                            <Button variant="yellow" className="pitchichi btn-pitchichi-primary mt-5 position-relative">Planifier un rendez-vous<img src={illustrationButton} alt="pitchichi" className="illustration-button mr-0 mr-md-5" /></Button>
                        </LinkContainer>
                    </Row>
                </Container>



                <SectionB />



                <div className="bg-primary ">
                    <Container fluid className="bg-primary firm-container-section-choices text-secondary py-5 fade-in-slow wmax-2160">
                        <Row className="d-flex flex-column text-center justify-content-center align-items-center mb-3 mb-md-5 line-height-09 pt-5">
                            <Col>
                                <div className="">
                                    <span className="font-size-55 font-nhaas65 text-uppercase">
                                        pourquoi{" "}
                                    </span>
                                    <span className="font-size-55 font-recoletalight text-uppercase">
                                        vous
                                    </span>
                                </div>
                                <div className="font-size-55 font-recoletalight text-uppercase">faire accompagner</div>
                                <div className="font-size-55 font-recoletalight text-uppercase">par Pitchichi ?</div>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-center align-items-center text-center">
                            <Col xs={2} className="mx-2">
                                <Row className="justify-content-center mb-4">
                                    <img src={choice2} alt="pitchichi" className="img-firm-choice2" />
                                </Row>
                            </Col>
                            <Col xs={2} className="mx-2">
                                <Row className="justify-content-center mb-4">
                                    <img src={choice3} alt="pitchichi" className="img-firm-choice3" />
                                </Row>
                            </Col>
                            <Col xs={2} className="mx-2">
                                <Row className="justify-content-center mb-4">
                                    <img src={choice4} alt="pitchichi" className="img-firm-choice4" />
                                </Row>
                            </Col>
                            <Col xs={2} className="mx-2">
                                <Row className="justify-content-center mb-4">
                                    <img src={choice5} alt="pitchichi" className="img-firm-choice5" />
                                </Row>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-center align-items-center text-center  line-height-11">
                            <Col xs={2} className="mx-2" >
                                <Row className="justify-content-center mb-4">
                                    <div className="font-size-24 font-recoletasemibold">Sa connaissance</div>
                                    <div className="font-size-24 font-recoletasemibold">parfaite du marché</div>
                                </Row>
                            </Col>
                            <Col xs={2} className="mx-2">
                                <Row className="justify-content-center mb-4">
                                    <div className="font-size-24 font-recoletasemibold">Son profil unique</div>
                                </Row>
                            </Col>
                            <Col xs={2} className="mx-2">
                                <Row className="justify-content-center mb-4">
                                    <div className="font-size-24 font-recoletasemibold">Son ambition créative</div>
                                </Row>
                            </Col>
                            <Col xs={2} className="mx-2">
                                <Row className="justify-content-center mb-4">
                                    <div className="font-size-24 font-recoletasemibold">Sa méthode</div>
                                    <div className="font-size-24 font-recoletasemibold">« La Création d’Abord ! »</div>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-center align-items-center text-center line-height-11 font-size-18 font-nhaas55 pb-5">
                            <Col xs={2} className="mx-2">
                                <Row className="justify-content-center flex-column px-md-2 px-lg-3">
                                    <div>
                                        Le monde des agences est en perpétuelle mutation, nous observons quotiennement ses nouveaux acteurs et ses nouvelles offres.
                                    </div>

                                </Row>
                            </Col>
                            <Col xs={2} className="mx-2">
                                <Row className="justify-content-center flex-column px-md-2 px-lg-3">
                                    <div>
                                        Le bon choix sera toujours le vôtre mais échanger avec un expert en création extérieur aux agences vous offrira un éclairage supplémentaire.
                                    </div>
                                </Row>
                            </Col>
                            <Col xs={2} className="mx-2">
                                <Row className="justify-content-center flex-column px-md-2 px-lg-3">
                                    <div>
                                        Lancer un appel d’offres avec Pitchichi est une plus-value remarquée par les agences, une caution de votre ambition créative.
                                    </div>
                                </Row>
                            </Col>
                            <Col xs={2} className="mx-2">
                                <Row className="justify-content-center flex-column px-md-2 px-lg-3">
                                    <div>
                                        Pour mettre l’annonceur dans la peau du consommateur, Pitchichi demande aux agences de commencer
                                        leur présentation directement par
                                        la création.
                                    </div>
                                </Row>
                            </Col>
                        </Row>

                    </Container >
                </div>
                <SectionC />

                <Container fluid className="bg-secondary justify-content-center d-flex p-0 fade-in-slow">
                    <img src={agencies} alt="pitchichi" className="d-none d-sm-block wmax-2160 firm-container-section-choices" />
                    <img src={listingMobile} alt="pitchichi" className="d-block d-sm-none" />
                </Container>

                <Container fluid className="firm bg-secondary d-flex justify-content-center align-items-center pb-3 flex-column">
                    <Row className="flex-column flex-md-row">
                        <LinkContainer to="/contact-pitchichi">
                            <Button variant="yellow" className="pitchichi btn-pitchichi-primary mt-5">Planifier un rendez-vous</Button>
                        </LinkContainer>
                    </Row>
                    <Row className="flex-column flex-md-row">
                        <div className="align-self-end text-primary mt-4">
                            <div className="d-flex flex-column text-right line-height-1 text-center mx-5">
                                <span className="font-nhaas45 font-size-16">Pour une compétition en 1 tour, comptez 7 à 10 semaines / Pour une compétition en 2 tours, 9 à 14 semaines</span>

                            </div>
                        </div>
                    </Row>

                </Container>

                <Container fluid className="firm-container-section-other-services text-primary position-relative wmax-2160">
                    <div className="leftree position-absolute">
                        <img src={leftTree} alt="pitchichi" />
                    </div>
                    <div className="rightree position-absolute d-flex d-sm-none">
                        <img src={rightTree} alt="pitchichi" />
                    </div>
                    <Row className="flex-column flex-md-row text-center text-md-left">
                        <Col className="d-flex align-items-center justify-content-center pb-5 pb-md-0">
                            <Row className="flex-column font-size-55 font-recoletalight text-uppercase line-height-09">
                                <div>Autres</div>
                                <div>services</div>
                            </Row>

                        </Col>
                        <Col className="d-flex flex-column align-items-center align-items-md-start">
                            <Row className="flex-column font-size-35 font-recoletasemibold line-height-09 pb-2 pb-md-0">
                                <div>Optimisation</div>
                                <div>de votre portefeuille</div>
                                <div>d’agences</div>

                            </Row>
                            <Row className="flex-column font-size-18 font-nhaas55 line-height-11 pr-md-5 max-w-300">
                                <br></br>
                                <div><span className="font-recoletasemibold">Notre objectif : </span>améliorer la performance globale de votre stratégie de communication en recherchant la cohérence et l’effervescence créative entre vos agences. Nous précisons le mode de collaboration entre les
                                    agences, délimitons le périmètre de chacune et établissons une hiérarchie suivant les domaines d’expertises.</div>
                            </Row>
                        </Col>
                        <Col className="d-flex flex-column align-items-center align-items-md-start pt-5 pt-md-0 ">
                            <Row className="flex-column font-size-35 font-recoletasemibold line-height-09 pb-2 pb-md-0">
                                <div>Intervention</div>
                                <div>dans votre relation</div>
                                <div>annonceur-agence</div>
                            </Row>
                            <Row className="flex-column font-size-18 font-nhaas55 line-height-11 pr-md-5 max-w-300">
                                <br></br>
                                <div>Grâce à notre expertise créative, extérieure à l’agence, vous bénéficiez d’un éclairage supplémentaire pour
                                    prendre les meilleures décisions à des moments-clés de la collaboration avec votre agence : choix de pistes créatives ou de moyens de production (participation aux PPM).</div>
                            </Row>
                        </Col>
                    </Row>
                </Container>

                <SectionGfirm />

                <SectionH />
                <SectionI />

            </div>
        </>
    );
}