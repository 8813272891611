import "../../App.scss";
import React from 'react';
import "./Offers.scss";
import { LinkContainer } from 'react-router-bootstrap'
import { Helmet } from "react-helmet";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import bulletpointgreen from "./../../assets/illustrations/bulletpoint-green.svg";
import bulletpointblue from "./../../assets/illustrations/bulletpoint-blue.svg";

export default function Offers() {
    return (
        <>
            <Helmet>
                <title>Pitchichi | Plateforme & cabinet de conseil en choix d'agences de communication | Trouvez l’agence parfaite (branding, digital, publicité, social media, luxe)
                </title>
            </Helmet>
            <Container fluid className="offers bg-blue justify-content-center d-flex flex-column fade-in-fast wmax-2160">
                <Row className="section-1 flex-column justify-content-center line-height-11 text-center align-items-center text-primary">
                    <span className="font-size-section-a-55 font-nhaas65 text-uppercase">
                        Conseil en choix d’agences
                    </span>

                    <span className="font-size-section-a-55 font-recoletalight text-uppercase">
                        pour entreprises voulant des marques
                    </span>
                    <span className="font-size-section-a-55 font-recoletalight text-uppercase">
                        fortes et inspirantes.
                    </span>
                </Row>
                <Row className="p-0 ">
                    <Col className="pl-md-5 pr-md-4 pl-3 pr-3">
                        <Card className="card-section mb-5 mb-md-0  card-rounded align-items-center text-primary border-0">
                            <Card.Body className="d-flex flex-column align-items-center w-100 p-0">
                                <Card.Title className="bg-green w-100 text-center">
                                    <span className="font-size-section-a-50 font-recoletasemibold">Plateforme</span>

                                </Card.Title>
                                <Card.Text className="line-height-1 mt-md-3 text-center px-3">
                                    <div className="font-size-35 font-nhaas45">Idéal pour les annonceurs qui veulent</div>
                                    <div className="font-size-35 font-nhaas45">gagner du temps et de l’énergie</div>
                                    <div className="font-size-35 font-nhaas45">en s’adressant directement aux bonnes agences.</div>
                                </Card.Text>
                                <Card.Text className="line-height-1 my-md-5 text-center">
                                    <div className="font-size-section-a-30 font-recoletasemibold text-underline">
                                        Recommandé pour budgets
                                    </div>
                                    <div className="font-size-52 py-1 font-weight-600 font-NHaas65">
                                        {"<"} 100K€
                                    </div>
                                    <div className="font-size-section-a-30 font-NHaas55">
                                        (honoraires agences)
                                    </div>

                                </Card.Text>
                                <Card.Text className="line-height-1 my-md-5 text-left block-3-left">
                                    <div className="mb-4">
                                        <span className="font-size-section-a-30 font-recoletasemibold">
                                            Avantages
                                        </span>
                                    </div>
                                    <div className="mb-2 mb-md-3 d-flex align-items-center">

                                        <img src={bulletpointgreen} width="5%" alt="pitchichi-offres-recherche-agences-communication" />
                                        <div className="d-flex"><span className="ml-4 font-size-section-a-25 font-recoletamedium">Entretien préalable :</span>
                                            <span className="font-size-section-a-25 font-nhaas55">&nbsp;30 minutes</span></div>

                                    </div>
                                    <div className="mb-2 mb-md-3 d-flex align-items-center">
                                        <img src={bulletpointgreen} width="5%" alt="pitchichi-offres-recherche-agences-communication" />
                                        <div className="d-flex"><span className="ml-4 font-size-section-a-25 font-recoletamedium">Recommandation :</span>
                                            <span className="font-size-section-a-25  font-nhaas55">&nbsp;5 agences</span></div>

                                    </div>
                                    <div className="mb-2 mb-md-3 d-flex align-items-center">
                                        <img src={bulletpointgreen} width="5%" alt="pitchichi-offres-recherche-agences-communication" />
                                        <span className="ml-4 font-size-section-a-25 font-nhaas55 line-height-12">Guide de bonnes pratiques pour une consultation d’agences</span>

                                    </div>
                                    <div className="mb-2 mb-md-3 d-flex align-items-center">
                                        <img src={bulletpointgreen} width="5%" alt="pitchichi-offres-recherche-agences-communication" />
                                        <div className="d-flex"><span className="ml-4 font-size-section-a-25 font-nhaas55">Conseil</span></div>

                                    </div>


                                </Card.Text>
                                <Card.Text className="line-height-1 mt-auto text-center mb-5">
                                    <div className="">
                                        <span className="font-size-section-a-25 font-NHaas55">
                                            Gratuit*
                                        </span>
                                    </div>
                                    <LinkContainer to="/wizard">
                                        <Button variant="yellow" className="btn-pitchichi-green my-3">Trouver mon agence</Button>
                                    </LinkContainer>
                                    <div><LinkContainer to="/">
                                        <span className="font-size-section-a-20 font-recoletaregular text-underline text-link">
                                            EN SAVOIR PLUS
                                        </span>
                                    </LinkContainer></div>



                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="pr-md-5 pl-md-4 pl-3 pr-3 mt-3 mt-md-0">
                        <Card className="card-section card-rounded align-items-center text-primary border-0">
                            <Card.Body className="d-flex flex-column align-items-center w-100 p-0">
                                <Card.Title className="bg-yellow w-100 text-center">
                                    <span className="font-size-section-a-50 font-recoletasemibold">Cabinet de conseil</span>

                                </Card.Title>
                                <Card.Text className="line-height-1 mt-md-3 text-center px-3">
                                    <div className="font-size-35 font-nhaas45">L’accompagnement sur-mesure pour les annonceurs qui veulent trouver les agences capables de les mener au sommet.</div>

                                </Card.Text>
                                <Card.Text className="line-height-1 my-md-5 text-center">
                                    <div className="font-size-section-a-30 font-recoletasemibold text-underline">
                                        Recommandé pour budgets
                                    </div>
                                    <div className="font-size-52 font-weight-600 py-1 font-NHaas65">
                                        {">"} 100K€
                                    </div>
                                    <div className="font-size-section-a-30 font-NHaas55">
                                        (honoraires agences)
                                    </div>

                                </Card.Text>
                                <Card.Text className="line-height-1 my-md-5 text-left block-3-right">
                                    <div className="mb-4 ">
                                        <span className="font-size-section-a-30 font-recoletasemibold">
                                            Avantages
                                        </span>
                                    </div>
                                    <div className="mb-2 mb-md-3 d-flex align-items-center">
                                        <img src={bulletpointblue} width="5%" alt="pitchichi-offres-recherche-agences-communication" />
                                        <div className="d-flex">
                                            <span className="ml-4 font-size-section-a-25  font-recoletamedium">Entretien préalable :</span>
                                            <span className="font-size-section-a-25  font-nhaas55">&nbsp;2 heures</span>

                                        </div>
                                    </div>
                                    <div className="mb-2 mb-md-3 d-flex align-items-center">
                                        <img src={bulletpointblue} width="5%" alt="pitchichi-offres-recherche-agences-communication" />
                                        <div className="d-flex">
                                            <span className="ml-4 font-size-section-a-25  font-recoletamedium">Recommandation :</span>{" "}
                                            <span className="font-size-section-a-25  font-nhaas55">&nbsp;10 à 15 agences</span>
                                        </div>

                                    </div>
                                    <div className="mb-2 mb-md-3 d-flex align-items-center">
                                        <img src={bulletpointblue} width="5%" alt="pitchichi-offres-recherche-agences-communication" />
                                        <span className="ml-4 font-size-section-a-25  font-nhaas55">Retravail du brief</span>
                                    </div>
                                    <div className="mb-2 mb-md-3 d-flex align-items-center">
                                        <img src={bulletpointblue} width="5%" alt="pitchichi-offres-recherche-agences-communication" />
                                        <span className="ml-4 font-size-section-a-25  font-nhaas55">Optimisation du format de la compétition</span>
                                    </div>
                                    <div className="mb-2 mb-md-3 d-flex align-items-center">
                                        <img src={bulletpointblue} width="5%" alt="pitchichi-offres-recherche-agences-communication" />
                                        <span className="ml-4 font-size-section-a-25  font-nhaas55 line-height-12">Organisation de toutes les étapes de la compétition d’agences</span>
                                    </div>
                                    <div className="mb-2 mb-md-3 d-flex align-items-center">
                                        <img src={bulletpointblue} width="5%" alt="pitchichi-offres-recherche-agences-communication" />
                                        <span className="ml-4 font-size-section-a-25  font-nhaas55">Participation aux réunions</span>
                                    </div>
                                    <div className="mb-2 mb-md-3 d-flex align-items-center">
                                        <img src={bulletpointblue} width="5%" alt="pitchichi-offres-recherche-agences-communication" />
                                        <span className="ml-4 font-size-section-a-25  font-nhaas55">Conseil</span>
                                    </div>
                                    <div className="mb-2 mb-md-3 d-flex align-items-center">
                                        <img src={bulletpointblue} width="5%" alt="pitchichi-offres-recherche-agences-communication" />
                                        <span className="ml-4 font-size-section-a-25  font-nhaas55">Annonce des résultats aux agences</span>
                                    </div>


                                </Card.Text>
                                <Card.Text className="line-height-1 mt-auto text-center mb-5">
                                    <div className="">
                                        <span className="font-size-section-a-25 font-NHaas55">
                                            Frais côté annonceur et côté agence*
                                        </span>
                                    </div>
                                    <LinkContainer to="/contact-pitchichi">
                                        <Button variant="yellow" className="btn-pitchichi-primary my-3">Planifier un rendez-vous</Button>
                                    </LinkContainer>
                                    <div className="">
                                        <LinkContainer to="/competition-agence-communication">
                                            <span className="font-size-section-a-20 font-recoletaregular text-underline text-link">
                                                EN SAVOIR PLUS
                                            </span>
                                        </LinkContainer>
                                    </div>


                                </Card.Text>

                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
                <Row className="flex-column text-center align-items-md-end line-height-1 text-primary mt-4 mx-5">
                    <span className="font-size-12 font-nhaas45">
                        *Nous sommes rémunérés
                    </span>

                    <span className="font-size-12 font-nhaas45">
                        par l’agence qui remporte votre projet
                    </span>
                </Row>
                <Row className="flex-column text-center align-items-center line-height-11 text-primary my-5 d-none d-sm-flex">
                    <span className="font-size-35 font-nhaas45">
                        Si le budget alloué à votre projet est inférieur à 100K€
                    </span>

                    <span className="font-size-35 font-nhaas45">
                        et que vous souhaitez quand même bénéficier des services
                    </span>
                    <span className="font-size-35 font-nhaas45">
                        de notre cabinet de conseil,{" "}
                        <LinkContainer to="/contact-pitchichi">
                            <span className="font-recoletasemibold text-underline text-link">contactez-nous.</span>
                        </LinkContainer>
                    </span>

                </Row>
                <Row className="text-center align-items-center line-height-11 text-primary my-4 d-block d-sm-none px-3">
                    <span className="font-size-35 font-nhaas45">
                        Si le budget alloué à votre projet est inférieur à 100K€

                        et que vous souhaitez quand même bénéficier des services de notre cabinet de conseil,{" "}
                    </span>
                    <LinkContainer to="/contact-pitchichi">
                        <span className="font-recoletasemibold text-underline text-link">contactez-nous.</span>
                    </LinkContainer>

                </Row>

            </Container>
        </>
    );
}