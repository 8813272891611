import "../../../App.scss";
import "./SectionC.scss";
import { LinkContainer } from 'react-router-bootstrap'

import React from 'react';

import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";


import card1 from "../../../assets/illustrations/main/card1.png.webp";
import card2 from "../../../assets/illustrations/main/card2.png.webp";

export default function SectionC() {
    return (
        <>
            <Container fluid className="home-section-c bg-secondary d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex flex-column align-items-center justify-content-center text-primary">
                    <div className="line-height-09 font-size-55 d-flex flex-column text-center">
                        <span className="font-recoletalight text-uppercase">quel{" "}<span className="font-nhaas65 text-uppercase">
                            annonceur
                        </span></span>
                        <span className="font-recoletalight text-uppercase">êtes-vous ?</span>
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row card-section align-items-center justify-content-between">
                    <Card className="card-section3 mr-0 mr-md-4 card-rounded bg-green text-center align-items-center text-primary">
                        <Card.Img variant="top" src={card1} className="mx-auto card-left" />
                        <Card.Body className="d-flex justify-content-around justify-content-md-end flex-column align-items-end align-items-md-center">
                            <Card.Title className="align-self-center">
                                <span className="font-size-20 font-recoletaregular text-uppercase text-underline">J’AI UN BUDGET ENTRE</span>
                                <span className="font-size-20 font-recoletasemibold text-uppercase text-underline"> 10</span>
                                <span className="font-size-20 font-recoletaregular text-uppercase text-underline"> ET</span>
                                <span className="font-size-20 font-recoletasemibold text-uppercase text-underline"> 100K€</span>
                                <span className="font-size-20 font-recoletaregular text-uppercase text-underline">*,</span>
                            </Card.Title>
                            <Card.Text className="description line-height-1 text-left text-md-center mb-1 mb-md-4">
                                <div>
                                    <span className="font-size-44 font-recoletalight">Je cherche</span>
                                </div>
                                <div>
                                    <span className="font-size-44 font-nhaas65 text-uppercase">la perle rare</span>
                                </div>
                            </Card.Text>
                            <Card.Text className="description font-nhaas55 font-size-18 line-height-1 line-height-1  mb-2 mb-md-5 text-left text-md-center">
                                <div>Gratuit et sans engagement, Pitchichi vous recommande</div>
                                <div>5 agences parfaitement adaptées.</div>

                            </Card.Text>
                            <LinkContainer to="/wizard">
                                <Button  className="btn-pitchichi-secondary align-self-center mb-0 mb-md-4">Trouver mon agence</Button>
                            </LinkContainer>
                        </Card.Body>
                    </Card>
                    <Card className="card-section3 mt-3 mt-md-0 ml-md-4 ml-0 card-rounded bg-blue text-center align-items-center text-primary">
                        <Card.Img variant="top" src={card2} className="mx-auto card-right" />
                        <Card.Body className="d-flex justify-content-around justify-content-md-end flex-column align-items-end align-items-md-center">
                            <Card.Title className="align-self-center">
                                <span className="font-size-20 font-recoletaregular text-uppercase text-underline">J’AI UN BUDGET SUPÉRIEUR À</span>

                                <span className="font-size-20 font-recoletasemibold text-uppercase text-underline"> 100K€</span>
                                <span className="font-size-20 font-recoletaregular text-uppercase text-underline">*,</span>
                            </Card.Title>
                            <Card.Text className="description line-height-1 text-left text-md-center mb-1 mb-md-4">
                                <div>
                                    <span className="font-size-44 font-recoletalight">Je vise</span>
                                </div>
                                <div>
                                    <span className="font-size-44 font-nhaas65 text-uppercase">les sommets</span>
                                </div>
                            </Card.Text>
                            <Card.Text className="description font-nhaas55 font-size-18 line-height-1 mb- mb-md-5 text-left text-md-center">
                                <div>Pitchichi vous propose ses conseils en choix d’agences</div>
                                <div>et organise votre appel d’offres de A à Z</div>

                            </Card.Text>
                            <LinkContainer to="/competition-agence-communication">
                                <Button className="btn-pitchichi-primary align-self-center mb-0 mb-md-4">Consulter le cabinet de conseil</Button>
                            </LinkContainer>
                        </Card.Body>
                    </Card>
                    
                </div>
                <div className="align-self-end text-primary mt-3">
                    <div className="d-flex flex-column text-right line-height-1">
                        <span className="font-nhaas45 font-size-12">*honoraires agence</span>
                        <span className="font-nhaas45 font-size-12">(hors budget de production et achat média)</span>

                    </div>
                </div>
            </Container>


        </>
    )
}