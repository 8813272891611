import "../../App.scss";
import React from 'react';
import axios from 'axios'
import { useHistory } from "react-router-dom";
import "./Wizard.scss";
import * as Survey from "survey-react";
import showdown from "showdown"
import "survey-react/survey.css";
import img from "./../../assets/illustrations/branding.svg"
import img2 from "./../../assets/illustrations/digital.svg"
import img3 from "./../../assets/illustrations/experentiel.svg"
import img4 from "./../../assets/illustrations/social.svg"
import img5 from "./../../assets/illustrations/campaign.svg"
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { API_URL } from "../../util/Constant";

export default function Wizard() {
    const history = useHistory()
    let json = {
        "locale": "fr",
        "goNextPageAutomatic": true,
        "questionErrorLocation": "bottom",
        "showProgressBar": "bottom",
        "pages": [
            {
                "name": "Question 1",
                "elements": [
                    {
                        "type": "imagepicker",
                        "name": "question1",
                        "minWidth": "200px",
                        "title": "De **quel service** <br /> avez-vous besoin ?",
                        "hideNumber": true,
                        "isRequired": true,


                        "choices": [
                            {
                                "value": "branding",
                                "text": "Branding",
                                "imageLink": img
                            },
                            {
                                "value": "digital",
                                "text": "Digital",
                                "imageLink": img2
                            },
                            {
                                "value": "publicite",
                                "text": "Publicité",
                                "imageLink": img5
                            },
                            {
                                "value": "social",
                                "text": "Social media",
                                "imageLink": img4
                            },
                            {
                                "value": "experientiel",
                                "text": "Expérientiel",
                                "imageLink": img3
                            }
                        ],
                        "imageFit": "contain",
                        "imageWidth": "100%",
                        "colCount": 5,
                        "showLabel": true
                    }
                ]
            },
            {
                "name": "Question-2-Branding",
                "elements": [
                    {
                        "type": "checkbox",
                        "name": "Question-2-Branding-Bullets",
                        "minWidth": "200px",
                        "visibleIf": "{question1} = 'branding'",
                        "title": "Pouvez-vous<br />**préciser ?**",
                        "hideNumber": true,
                        "isRequired": true,

                        "choices": [
                            {
                                "value": "Penser ma stratégie de positionnement de marque",
                                "text": "Penser ma stratégie de positionnement de marque"
                            },
                            {
                                "value": "Créer une identité de marque avec un logo et une charte graphique",
                                "text": "Créer une identité de marque avec un logo et une charte graphique"
                            },
                            {
                                "value": "Faire le design d'un emballage (packaging)",
                                "text": "Faire le design d'un emballage (packaging)"
                            },
                            {
                                "value": "Trouver une direction artistique pour la marque (iconographie)",
                                "text": "Trouver une direction artistique pour la marque (iconographie)"
                            },
                            {
                                "value": "Trouver un style rédactionnel pour la marque (ton)",
                                "text": "Trouver un style rédactionnel pour la marque (ton)"
                            }
                        ],
                        "hasOther": true,
                        "otherText": "Autre (veuillez spécifier)"
                    }
                ],
                "visibleIf": "{question1} = 'branding'"
            },
            {
                "name": "Question-2-Social",
                "elements": [
                    {
                        "type": "checkbox",
                        "minWidth": "200px",
                        "name": "Question-2-Social-Bullets",
                        "title": "Pouvez-vous<br />**préciser ?**",
                        "hideNumber": true,
                        "isRequired": true,

                        "choices": [
                            "Faire de la publicité sur les réseaux sociaux (recrutement, viralisation, vente)",
                            "Entrer en contact avec des influenceurs",
                            "Gérer mon community management",
                            "Mettre en place un planning de contenus (storytelling, photos, illustrations, videos, rédactionnel)",
                        ],
                        "hasOther": true,
                        "otherText": "Autre (veuillez spécifier)"
                    }
                ],
                "visibleIf": "{question1} = 'social'"
            },
            {
                "name": "Question-2-Digital",
                "elements": [
                    {
                        "type": "checkbox",
                        "minWidth": "200px",
                        "name": "Question-2-Digital-Bullets",
                        "title": "Pouvez-vous<br />**préciser ?**",
                        "hideNumber": true,
                        "isRequired": true,

                        "choices": [
                            "Créer une campagne publicitaire 100% en ligne",
                            "Développer un site (marque ou e-commerce)",
                            "Développer une application",
                            "Investir dans le référencement payant (SEO, SEA, Adwords)",
                            "Faire du growth hacking",
                            "Créer un jeu ou une expérience digitale (DOOH)",
                        ],
                        "hasOther": true,
                        "otherText": "Autre (veuillez spécifier)"
                    }
                ],
                "visibleIf": "{question1} = 'digital'"
            },
            {
                "name": "Question-2-Experientiel",
                "elements": [
                    {
                        "type": "checkbox",
                        "minWidth": "200px",
                        "name": "Question-2-Experientiel-Bullets",
                        "title": "Pouvez-vous<br />**préciser ?**",
                        "hideNumber": true,
                        "isRequired": true,

                        "choices": [
                            "Organiser un événement hors du commun",
                            "Créer un lieu de vente/exposition hors du commun",
                            "Créer un lieu de vente/exposition éphémère hors du commun",
                        ],
                        "hasOther": true,
                        "otherText": "Autre (veuillez spécifier)"
                    }
                ],
                "visibleIf": "{question1} = 'experientiel'"
            },
            {
                "name": "Question-2-Publicite",
                "elements": [
                    {
                        "type": "checkbox",
                        "minWidth": "200px",
                        "name": "Question-2-Publicite-Bullets",
                        "title": "Pouvez-vous<br />**préciser ?**",
                        "hideNumber": true,
                        "isRequired": true,

                        "choices": [
                            "Créer une campagne publicitaire déclinable dans le temps et sur différents supports (angle d'attaque du marché + big idea + décli)",
                            "Créer une opération d'activation de marque (digital et/ou autres supports)",
                            "Faire un ou plusieurs films ",
                            "Faire une ou plusieurs radios",
                            "Faire un ou plusieurs print (affichage/presse)",
                        ],
                        "hasOther": true,
                        "otherText": "Autre (veuillez spécifier)"
                    }
                ],
                "visibleIf": "{question1} = 'publicite'"
            },
            {
                "name": "Question-3-Branding",
                "elements": [
                    {
                        "type": "checkbox",
                        "minWidth": "200px",
                        "name": "Question-3-Branding-Bullets-2",
                        "title": "Quel est **l'objectif** derrière votre projet ?",
                        "hideNumber": true,
                        "isRequired": true,

                        "choices": [
                            "Lancer un nouveau produit",
                            "Lancer l'entreprise",
                            "Adapter mon image de marque à un nouveau marché",
                            "Moderniser mon image de marque qui est dépassée",
                            "Améliorer mon image de marque suite à une levée de fonds",
                            "Adapter mon image de marque après une fusion/acquisition",
                        ],
                        "hasOther": true,
                        "otherText": "Autre (veuillez spécifier)"
                    }
                ],
                "visibleIf": "{question1} = 'branding'"
            },
            {
                "name": "Question-3-Social",
                "elements": [
                    {
                        "type": "checkbox",
                        "minWidth": "200px",
                        "name": "Question-3-Social-Bullets-2",
                        "title": "Quel est **l'objectif** derrière votre projet ?",
                        "hideNumber": true,
                        "isRequired": true,

                        "choices": [
                            "Atteindre des personnes spécifiques en ligne",
                            "Atteindre un public plus jeune",
                            "Accroître l'engagement et la fidélité de mes clients",
                            "Augmenter le nombre de mes followers",
                            "Être mieux référencé sur Google",
                            "Créer une position de leader d'opinion sur mon secteur",
                            "Générer plus de ventes sur mon e-commerce",
                        ],
                        "hasOther": true,
                        "otherText": "Autre (veuillez spécifier)"
                    }
                ],
                "visibleIf": "{question1} = 'social'"
            },
            {
                "name": "Question-3-Digital",
                "elements": [
                    {
                        "type": "checkbox",
                        "minWidth": "200px",
                        "name": "Question-3-Digital-Bullets-2",
                        "title": "Quel est **l'objectif** derrière votre projet ?",
                        "hideNumber": true,
                        "isRequired": true,

                        "choices": [
                            "Atteindre des personnes spécifiques en ligne",
                            "Atteindre un public de masse",
                            "Atteindre un public plus jeune",
                            "Améliorer l'image de l'entreprise (long-terme)",
                            "Améliorer la visibilité de l'entreprise (court-terme)",
                            "Augmenter nos ventes en ligne",
                            "Augmenter le nombre de visites d'un site",
                            "Accroître l'engagement et la fidélité de mes clients",
                            "Créer une communauté active",
                            "Créer l'évènement autour d'un lancement produit",
                            "Mieux traquer et comprendre notre audience",
                        ],
                        "hasOther": true,
                        "otherText": "Autre (veuillez spécifier)",
                        "colCount": 2
                    }
                ],
                "visibleIf": "{question1} = 'digital'"
            },
            {
                "name": "Question-3-Experientiel",
                "elements": [
                    {
                        "type": "checkbox",
                        "minWidth": "200px",
                        "name": "Question-3-Experientiel-Bullets-2",
                        "title": "Quel est **l'objectif** derrière votre projet ?",
                        "hideNumber": true,
                        "isRequired": true,

                        "choices": [
                            "Créer le buzz avec un concept out-of-the-box",
                            "Remercier mes meilleurs clients",
                            "Célébrer un événement important",
                            "Lancer un produit",
                            "Activer le bouche-à-oreille",
                        ],
                        "hasOther": true,
                        "otherText": "Autre (veuillez spécifier)"
                    }
                ],
                "visibleIf": "{question1} = 'experientiel'"
            },
            {
                "name": "Question-3-Ad",
                "elements": [
                    {
                        "type": "checkbox",
                        "minWidth": "200px",
                        "name": "Quel est **l'objectif** derrière votre projet ?",
                        "hideNumber": true,
                        "isRequired": true,

                        "choices": [
                            "Atteindre un public de masse",
                            "Atteindre un public de niche",
                            "Améliorer l'image de l'entreprise (long-terme)",
                            "Améliorer la visibilité de la marque (court-terme)",
                            "Promouvoir un produit spécifique et augmenter ses ventes",
                            "Amener des clients potentiels sur le site",
                            "Amener des clients potentiels en boutique",
                        ],
                        "hasOther": true,
                        "otherText": "Autre (veuillez spécifier)"
                    }
                ],
                "visibleIf": "{question1} = 'publicite'"
            },
            {
                "name": "Question-4-Branding",
                "elements": [
                    {
                        "type": "radiogroup",
                        "minWidth": "200px",
                        "name": "Question-4-Branding-Bullets-3",
                        "title": "Avez-vous déjà une **charte graphique** ou un **'Brand Book'** ?",
                        "hideNumber": true,
                        "isRequired": true,

                        "choices": [
                            "Non, mais je n'en ai pas besoin",
                            "Non, j'ai besoin d'un pack Branding complet avec une charte graphique",
                            "Oui, mais cela peut être adapté",
                            "Oui, à suivre strictement",
                        ],
                        "hasOther": true,
                        "otherText": "Autre (veuillez spécifier)"
                    }
                ],
                "visibleIf": "{question1} = 'branding'"
            },
            {
                "name": "Question-4-Social",
                "elements": [
                    {
                        "type": "checkbox",
                        "minWidth": "200px",
                        "name": "Question-4-Social-Bullets-3",
                        "title": "Sur **quels réseaux** le contenu sera-t-il publié?",
                        "hideNumber": true,
                        "isRequired": true,

                        "choices": [
                            "Facebook",
                            "Linkedin",
                            "Instagram",
                            "TikTok",
                            "Mon site web",
                        ],
                        "hasOther": true,
                        "otherText": "Autre (veuillez spécifier)"
                    }
                ],
                "visibleIf": "{question1} = 'social'"
            },
            {
                "name": "Question-4d-Experientiel",
                "elements": [
                    {
                        "type": "checkbox",
                        "minWidth": "200px",
                        "name": "Question-4d-Experientiel-Bullet",
                        "title": "Avez-vous besoin **d‘autres services** ?",
                        "hideNumber": true,
                        "isRequired": true,

                        "choices": [
                            "Création de contenu à partir de l'évènement",
                            "Promotion de l'événement",
                        ],
                        "hasOther": true,
                        "otherText": "Autre (veuillez spécifier)"
                    }
                ],
                "visibleIf": "{question1} = 'experientiel'"
            },
            {
                "name": "Question-4e-Experientiel",
                "elements": [
                    {
                        "type": "radiogroup",
                        "minWidth": "200px",
                        "name": "Question-4e-Experientiel-bullet",
                        "title": "Quelle est **l'importance du digital** dans l'expérience ? ",
                        "hideNumber": true,
                        "isRequired": true,

                        "choices": [
                            "Totale",
                            "Grande",
                            "50/50",
                            "Faible",
                            "Nulle"
                        ]
                    }
                ],
                "visibleIf": "{question1} = 'experientiel'"
            },
            {
                "name": "Question-4f-Experientiel",
                "elements": [
                    {
                        "type": "radiogroup",
                        "minWidth": "200px",
                        "name": "Question-4f-Experientiel-Bullets",
                        "title": "**Combien d'invités** attendez-vous ?",
                        "hideNumber": true,
                        "isRequired": true,

                        "choices": [
                            "Moins de 10",
                            "Entre 11 et 50",
                            "Entre 51 et 99",
                            "Plus de 100",
                        ],
                        "hasOther": true,
                        "otherText": "Autre (veuillez spécifier)"
                    }
                ],
                "visibleIf": "{question1} = 'experientiel'"
            },
            {
                "name": "Question-5",
                "elements": [
                    {
                        "type": "checkbox",
                        "minWidth": "200px",
                        "name": "Question-5-Checkbox",
                        "title": "Lesquels de ces termes correspondent à **votre cible** ? (optionnel)",
                        "hideNumber": true,

                        "choices": [
                            "Populaire",
                            "Arty-Hype",
                            "Bourgeois-Chic",
                            "Ecolo",
                            "Engagé",
                            "Rock",
                            "Culture Street",
                            "Senior",
                            "Geek",
                            "Sportif",
                            "Millenials",
                            "GenZ",
                            "Style plutôt masculin",
                            "Style plutôt féminin"
                        ],
                        "hasOther": true,
                        "otherText": "Autre (veuillez spécifier)",
                        "colCount": 3
                    }
                ]
            },
            {
                "name": "Question-6",
                "elements": [
                    {
                        "type": "radiogroup",
                        "minWidth": "200px",
                        "name": "Questiopn-6-Bullets",
                        "title": "Quelle est **votre position** ?",
                        "hideNumber": true,
                        "isRequired": true,

                        "choices": [
                            "CEO, Fondateur, Associé",
                            "Directeur Marketing/Communication, Brand Director",
                            "Membre de l'équipe Marketing/Communication",
                            "Consultant",
                            "Agence de Marketing/Communication",
                            "Représentant des Ventes, Développeur d'Affaires",
                            "Etudiant",
                        ],
                        "hasOther": true,
                        "otherText": "Autre (veuillez spécifier)"
                    }
                ]
            },
            {
                "name": "Question-7",
                "elements": [
                    {
                        "type": "checkbox",
                        "minWidth": "200px",
                        "name": "Question-7-Bullets",
                        "title": "**Que souhaitez-vous faire** grâce à Pitchichi ?",
                        "hideNumber": true,
                        "isRequired": true,

                        "choices": [
                            "Travailler avec la meilleure agence pour mon projet",
                            "Explorer de nouvelles opportunités de communication sans idée précise",
                            "Avoir une estimation pour mon Business-Plan",
                            "Je teste juste",
                        ],
                        "hasOther": true,
                        "otherText": "Autre (veuillez spécifier)"
                    }
                ]
            },
            {
                "name": "Question-8",
                "elements": [
                    {
                        "type": "radiogroup",
                        "minWidth": "200px",
                        "name": "Question-8-Bullets",
                        "title": "Quelle **gamme de budget** serait confortable pour vous ? (production incluse)",
                        "hideNumber": true,
                        "isRequired": true,
                        "choices": [
                            {
                                "value": "Inférieure à 10 000€",
                                "text": "Inférieure à 10 000€"
                            },
                            {
                                "value": "Entre 10 000€ et 25 000 €",
                                "text": "Entre 10 000€ et 25 000 €"
                            },
                            {
                                "value": "Entre 25 000€ et 50 000 €",
                                "text": "Entre 25 000€ et 50 000 €"
                            },
                            {
                                "value": "Entre 50 000€ et 100 000 €",
                                "text": "Entre 50 000€ et 100 000 €"
                            },
                            {
                                "value": "Entre 100 000€ et 250 000 €",
                                "text": "Entre 100 000€ et 250 000 €"
                            }
                        ],
                        "hasOther": true,
                        "otherText": {
                            "default": "Autre (veuillez spécifier)",
                            "fr": "Supérieure à 250 000€ (veuillez préciser) "
                        },
                        "visibleIf": "{question1} = 'branding' or {question1} = 'digital'"
                    },
                    {
                        "type": "radiogroup",
                        "minWidth": "200px",
                        "name": "Question-8-Bulletss",
                        "title": "Quelle **gamme de budget** serait confortable pour vous ? (production incluse)",
                        "hideNumber": true,
                        "isRequired": true,
                        "choices": [
                            {
                                "value": "Inférieure à 10 000€",
                                "text": "Inférieure à 10 000€"
                            },
                            {
                                "value": "Entre 10 000€ et 25 000 €",
                                "text": "Entre 10 000€ et 25 000 €"
                            },
                            {
                                "value": "Entre 25 000€ et 50 000 €",
                                "text": "Entre 25 000€ et 50 000 €"
                            },
                            {
                                "value": "Entre 50 000€ et 100 000 €",
                                "text": "Entre 50 000€ et 100 000 €"
                            },
                            {
                                "value": "Entre 100 000€ et 250 000 €",
                                "text": "Entre 100 000€ et 250 000 €"
                            },
                            {
                                "value": "Entre 250 000€ et 500 000 €",
                                "text": "Entre 250 000€ et 500 000 €"
                            },
                            {
                                "value": "Entre 500 000€ et 1 000 000 €",
                                "text": "Entre 500 000€ et 1 000 000 €"
                            }
                        ],
                        "hasOther": true,
                        "otherText": {
                            "default": "Autre (veuillez spécifier)",
                            "fr": "Supérieure à 1 000 000€ (veuillez préciser) "
                        },
                        "visibleIf": "{question1} = 'publicite' or {question1} = 'social' or {question1} = 'experientiel'"
                    },
                    {
                        "type": "checkbox",
                        "name": "question2",
                        "titleLocation": "hidden",
                        "hideNumber": true,
                        "choices": [
                            {
                                "value": "Il s'agit d'un budget ponctuel (c'est à dire non récurrent)",
                                "text": {
                                    "fr": "Il s'agit d'un budget ponctuel (c'est à dire non récurrent)"
                                }
                            },
                            {
                                "value": "Les dépenses médias ne sont pas incluses dans ce budget",
                                "text": {
                                    "fr": "Les dépenses médias ne sont pas incluses dans ce budget"
                                }
                            }
                        ]
                    },
                    {
                        "type": "text",
                        "name": "question3",
                        "titleLocation": "hidden",
                        "hideNumber": true,
                        "placeHolder": {
                            "fr": "Quel est le budget envisagé en media?"
                        }
                    }
                ]
            },
            {
                "name": "Question-9",
                "elements": [
                    {
                        "type": "radiogroup",
                        "minWidth": "200px",
                        "name": "question4",
                        "title": "Dans **combien de temps** votre projet doit-il être prêt (pour diffusion) ?",
                        "hideNumber": true,
                        "isRequired": true,
                        "choices": [
                            {
                                "value": "1 mois environ",
                                "text": {
                                    "fr": "1 mois environ"
                                }
                            },
                            {
                                "value": "2 mois environ",
                                "text": {
                                    "fr": "2 mois environ"
                                }
                            },
                            {
                                "value": "3 mois environ",
                                "text": {
                                    "fr": "3 mois environ"
                                }
                            },
                            {
                                "value": "Entre 4 et 5 mois environ",
                                "text": {
                                    "fr": "Entre 4 et 5 mois environ"
                                }
                            },
                            {
                                "value": "6 mois et plus",
                                "text": {
                                    "fr": "6 mois et plus"
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Question-10",
                "elements": [
                    {
                        "type": "radiogroup",
                        "minWidth": "200px",
                        "name": "question5",
                        "title": "Quelle **taille d'agence** préférez-vous ?",
                        "hideNumber": true,
                        "isRequired": true,
                        "choices": [
                            {
                                "value": "Petite (2 à 20 personnes)",
                                "text": {
                                    "fr": "Petite (2 à 20 personnes)"
                                }
                            },
                            {
                                "value": "Moyenne (21 à 50 personnes)",
                                "text": {
                                    "fr": "Moyenne (21 à 50 personnes)"
                                }
                            },
                            {
                                "value": "Grande (51 à 150 personnes)",
                                "text": {
                                    "fr": "Grande (51 à 150 personnes)"
                                }
                            },
                            {
                                "value": "Groupe (+ de 150 personnes)",
                                "text": {
                                    "fr": "Groupe (+ de 150 personnes)"
                                }
                            },
                            {
                                "value": "Peu importe",
                                "text": {
                                    "fr": "Peu importe"
                                }
                            }
                        ],
                        "hasOther": true,
                        "otherText": {
                            "fr": "Autre (veuillez spécifier)"
                        }
                    }
                ]
            },
            {
                "name": "Question-11",
                "elements": [
                    {
                        "type": "comment",
                        "minWidth": "200px",
                        "name": "question6",
                        "title": "Pourriez-vous décrire **votre projet** en quelques mots ?",
                        "hideNumber": true,
                        "placeHolder": {
                            "fr": "Décrivez votre projet ici"
                        }
                    }
                ]
            },
            {
                "name": "Question-12",
                "elements": [
                    {
                        "type": "multipletext",
                        "minWidth": "200px",
                        "name": "question12",
                        "title": "Parlez-nous de **votre entreprise / organisation** :",
                        "isRequired": true,
                        "hideNumber": true,
                        "items": [
                            {
                                "placeHolder": {
                                    "fr": "Nom de votre entreprise / organisation"
                                },
                                "title": {
                                    "fr": "Nom de votre entreprise / organisation"
                                },
                                "isRequired": true,
                                "name": "enterprisename"
                            },
                            {
                                "placeHolder": {
                                    "fr": "Nombre de salariés"
                                },
                                "title": {
                                    "fr": "Nombre de salariés"
                                },
                                "isRequired": true,
                                "name": "numberemployee",
                                "inputType": "number"
                            },
                            {
                                "placeHolder": {
                                    "fr": "Chiffre d'affaires"
                                }
                                ,
                                "title": {
                                    "fr": "Chiffre d'affaires"
                                },
                                "name": "totalca",
                                "inputType": "number"
                            }
                        ]
                    },
                    {
                        "type": "dropdown",
                        "name": "question8",
                        "isRequired": true,
                        "title": {
                            "fr": "Département du siège social :"
                        },
                        "hideNumber": true,
                        "choices": [
                            "Ain",
                            "Aisne",
                            "Allier",
                            "Alpes-de-Haute-Provence",
                            "Alpes-Maritimes",
                            "Ardèche",
                            "Ardennes",
                            "Ariège",
                            "Aube",
                            "Aude",
                            "Aveyron",
                            "Bas-Rhin",
                            "Bouches-du-Rhône",
                            "Calvados",
                            "Cantal",
                            "Charente",
                            "Charente-Maritime",
                            "Cher",
                            "Corrèze",
                            "Corse-du-Sud",
                            "Côte-d'Or",
                            "Côtes-d'Armor",
                            "Creuse",
                            "Deux-Sèvres",
                            "DOM-TOM",
                            "Dordogne",
                            "Doubs",
                            "Drôme",
                            "Essonne",
                            "Eure",
                            "Eure-et-Loir",
                            "Finistère",
                            "Gard",
                            "Gers",
                            "Gironde",
                            "Haut-Rhin",
                            "Haute-Corse",
                            "Haute-Garonne",
                            "Haute-Loire",
                            "Haute-Marne",
                            "Haute-Saône",
                            "Haute-Savoie",
                            "Haute-Vienne",
                            "Hautes-Alpes",
                            "Hautes-Pyrénées",
                            "Hauts-de-Seine",
                            "Hérault",
                            "Ille-et-Vilaine",
                            "Indre",
                            "Indre-et-Loire",
                            "Isère",
                            "Jura",
                            "Landes",
                            "Loir-et-Cher",
                            "Loire",
                            "Loire-Atlantique",
                            "Loiret",
                            "Lot",
                            "Lot-et-Garonne",
                            "Lozère",
                            "Maine-et-Loire",
                            "Manche",
                            "Marne",
                            "Mayenne",
                            "Meurthe-et-Moselle",
                            "Meuse",
                            "Morbihan",
                            "Moselle",
                            "Nièvre",
                            "Nord",
                            "Oise",
                            "Orne",
                            "Paris",
                            "Pas-de-Calais",
                            "Puy-de-Dôme",
                            "Pyrénées-Atlantiques",
                            "Pyrénées-Orientales",
                            "Rhône",
                            "Saône-et-Loire",
                            "Sarthe",
                            "Savoie",
                            "Seine-et-Marne",
                            "Seine-Maritime",
                            "Seine-Saint-Denis",
                            "Somme",
                            "Tarn",
                            "Tarn-et-Garonne",
                            "Territoire de Belfort",
                            "Val-d'Oise",
                            "Val-de-Marne",
                            "Var",
                            "Vaucluse",
                            "Vendée",
                            "Vienne",
                            "Vosges",
                            "Yonne",
                            "Yvelines"
                        ]
                    },
                    {
                        "type": "dropdown",
                        "name": "question9",
                        "title": {
                            "fr": "Votre principal secteur d'activités :"
                        },
                        "hideNumber": true,
                        "isRequired": true,
                        "choices": [
                            "Accessoires & vêtements",
                            "Aérospatial",
                            "Agriculture",
                            "Alimentaire",
                            "Animaux",
                            "Architecture et Urbanisme",
                            "Art & Artisanat",
                            "Assurance",
                            "Automobile",
                            "Banque et Finance",
                            "Biens et services industriels",
                            "Boissons",
                            "Comptabilité",
                            "Conseil en management",
                            "Construction",
                            "E-commerce",
                            "Edition",
                            "Éducation",
                            "Electronique",
                            "Energie et Pétrole",
                            "Événements et Spectacles",
                            "Gouvernement et administration",
                            "Hôpitaux et Santé",
                            "Immobilier",
                            "Internet",
                            "Jouets",
                            "Location d'espaces",
                            "Logiciels et services informatiques",
                            "Logistique & Supply Chain",
                            "Luxe et Joaillerie",
                            "Maison & Décoration",
                            "Marketing & Publicité",
                            "Matériel et Equipement IT",
                            "Médias",
                            "Militaire",
                            "Musique",
                            "Organisation politique",
                            "Pharmaceutique et Biotech",
                            "Produits ménagers",
                            "Ressources humaines",
                            "Restaurants",
                            "Sans but lucratif",
                            "Santé & Bien-être",
                            "Services à domicile",
                            "Services juridiques",
                            "Sport",
                            "Stockage",
                            "Tabac",
                            "Télécommunications",
                            "Transport",
                            "Utilitaires",
                            "Vente au détail",
                            "Vins et Spiritueux",
                            "Voyage et Loisirs"
                        ]
                    },
                    {
                        "type": "radiogroup",
                        "name": "question10",
                        "titleLocation": "hidden",
                        "hideNumber": true,
                        "isRequired": true,
                        "choices": [
                            {
                                "value": "Nous sommes une entreprise / organisation déjà établie",
                                "text": {
                                    "fr": "Nous sommes une entreprise / organisation déjà établie"
                                }
                            },
                            {
                                "value": "Nous sommes une start-up",
                                "text": {
                                    "fr": "Nous sommes une start-up"
                                }
                            },
                            {
                                "value": "Nous sommes une entreprise / organisation en cours de création",
                                "text": {
                                    "fr": "Nous sommes une entreprise / organisation en cours de création"
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Question-13",
                "elements": [
                    {
                        "type": "multipletext",
                        "name": "question13",
                        "minWidth": "200px",
                        "title": "Merci de nous indiquer **vos coordonnées** pour recevoir votre sélection d'agences :",
                        "hideNumber": true,
                        "items": [
                            {
                                "placeHolder": {
                                    "fr": "Votre prénom"
                                },
                                "title": {
                                    "fr": "Prénom"
                                },
                                "isRequired": true,
                                "name": "firstname"
                            },
                            {
                                "placeHolder": {
                                    "fr": "Votre nom"
                                },
                                "title": {
                                    "fr": "Nom"
                                },
                                "isRequired": true,
                                "name": "name"
                            },
                            {
                                "name": "email",
                                "placeHolder": {
                                    "fr": "Votre adresse e-mail"
                                },
                                "inputType": "email",
                                "title": "E-mail",
                                "isRequired": true,
                            },
                            {
                                "name": "phone",
                                "placeHolder": {
                                    "fr": "Votre numéro de téléphone"
                                },
                                "inputType": "tel",
                                "title": {
                                    "fr": "Téléphone"
                                }, "validators": [
                                    {
                                        "type": "numeric"
                                    }
                                ],
                                "isRequired": true,
                            }
                        ]
                    }
                ]
            }
        ]

    }

    var survey = new Survey.Model(json);
    function doOnCurrentPageChanged(survey) {
        window.scrollTo(0, 0)

    }

    function doOnCompleted(survey) {
        const response = modifySurveyResults(survey)

        axios.post(API_URL + "/wizard/quick-client-answers/", response)
            .then((response) => {
                history.push('/wizard-completed')

            })
            .catch(error => {
                history.push('/wizard-completed')
            })


    }

    function modifySurveyResults(survey) {
        var resultData = {
            "client": {
                "email": "xxx@yyy.zzz",
                "phone": "1234567"
            },
            "wizard": []
        };
        for (var key in survey.data) {
            var question = survey.getQuestionByValueName(key);
            if (!!question) {
                var item = {
                    "answers": []
                };
                //If question name (question.valueName) doesn't equal to question.title
                if (key !== question.title) {
                    item.question = question.title;
                }
                //If question value different from displayValue
                if (item.value !== question.displayValue) {
                    const text = {}
                    text.text = question.displayValue
                    item.answers.push(text)
                }
                //If the custom property tag is defined set
                if (question.tag !== undefined) {
                    item.tag = question.tag;
                }
                if (question.name === "question13") {
                    resultData.client.email = question.propertyHash.items[2].value
                    resultData.client.phone = question.propertyHash.items[3].value
                }

                resultData.wizard.push(item);
            }
        }
        return resultData;
    }


    survey.onTextMarkdown.add(function (survey, options) {
        var str = converter.makeHtml(options.text);
        str = str.substring(3);
        str = str.substring(0, str.length - 4);
        options.html = str;
    });
    survey
        .onUpdateQuestionCssClasses
        .add(function (survey, options) {
            var classes = options.cssClasses
            if (options.question.getType() === "imagepicker") {
                classes.root += " imagepicker";
            }
            if (options.question.getType() === "text") {
                classes.root += " question-text";
            }
            if (options.question.getType() === "comment") {
                classes.root += " question-comment";
            }
            if (options.question.getType() === "multipletext") {
                classes.root += " question-multipletext";
            }
        });
    var converter = new showdown.Converter();
    return (
        <>
            <Helmet>
                <title>Pitchichi | Trouvez l’agence parfaite | Plateforme de recommandation d’agences de communication | Site recherche et conseil d’agences
                </title>
                <meta name="description" content="Trouvez votre agence de communication gratuitement et en quelques clics grâce à notre questionnaire." />
                <link rel="pitchichi" href="https://www.pitchichi.com/wizard" />
            </Helmet>
            <Container fluid className="wizard bg-secondary justify-content-center d-flex fade-in-slow">
                <Row className="wizard-row justify-content-center p-0">
                    <Col className="align-items-center justify-content-center d-flex  text-primary p-0">

                        <Survey.Survey json={json} model={survey} onCurrentPageChanged={doOnCurrentPageChanged} onComplete={doOnCompleted} />

                    </Col>
                </Row>
            </Container>
        </>
    );
}