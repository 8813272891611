import "../../App.scss";
import React from "react";
import { Helmet } from "react-helmet";
import SectionA from "./Sections/SectionA";
import SectionB from "./Sections/SectionB";
import SectionC from "./Sections/SectionC";
import SectionD from "./Sections/SectionD";
import SectionE from "./Sections/SectionE";
import SectionF from "./Sections/SectionF";
import SectionG from "./Sections/SectionG";
import SectionH from "./Sections/SectionH";
import SectionI from "./Sections/SectionI";

export default function Home() {

    return (
        <>
            <Helmet>
                <title>Pitchichi | Plateforme de recommandation & conseil en choix d'agences de communication | Trouvez l’agence parfaite (branding, digital, publicité, social media, luxe)
                </title>
            </Helmet>
            <SectionA />
            <SectionB />
            <SectionC />
            <SectionD />
            <SectionE />
            <SectionF />
            <SectionG />
            <SectionH />
            <SectionI />
        </>
    );
}