import "../../App.scss";
import React from "react";

import "./Guide.scss";
import { LinkContainer } from 'react-router-bootstrap'
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import branding from "./../../assets/illustrations/branding.svg";
import social from "./../../assets/illustrations/social.svg";
import campaign from "./../../assets/illustrations/campaign.svg";
import luxe from "./../../assets/illustrations/luxe.svg";
import experentiel from "./../../assets/illustrations/experentiel.svg";
import digital from "./../../assets/illustrations/digital.svg";
import card1 from "./../../assets/illustrations/main/card1.png.webp";

export default function Guide() {

    const categories = [
        {
            img: branding,
            title: "Branding",
            alt: "pitchichi"
        },
        {
            img: digital,
            title: "Digital",
            alt: "pitchichi"
        },
        {
            img: campaign,
            title: "Publicité",
            alt: "pitchichi"
        },

        {
            img: luxe,
            title: "Luxe",
            alt: "pitchichi"
        },

        {
            img: social,
            title: "Social media",
            alt: "pitchichi"
        },
        {
            img: experentiel,
            title: "Expérientiel",
            alt: "pitchichi"
        }
    ]

    const cities = [
        {
            img: campaign,
            title: "Nantes",
            alt: "pitchichi"
        },
        {
            img: branding,
            title: "Bordeaux",
            alt: "pitchichi"
        },
        {
            img: luxe,
            title: "Lille",
            alt: "pitchichi"
        },
        {
            img: digital,
            title: "Paris",
            alt: "pitchichi"
        },
        {
            img: experentiel,
            title: "Lyon",
            alt: "pitchichi"
        },
        {
            img: social,
            title: "Marseille",
            alt: "pitchichi"
        },
    ]
    const regions = [
        {
            img: campaign,
            title: "Ouest",
            alt: "pitchichi"
        },
        {
            img: branding,
            title: "Sud-Ouest",
            alt: "pitchichi"
        },
        {
            img: digital,
            title: "Nord",
            alt: "pitchichi"
        },
        {
            img: social,
            title: "Centre",
            alt: "pitchichi"
        },
        {
            img: experentiel,
            title: "Sud-Est",
            alt: "pitchichi"
        },
        {
            img: luxe,
            title: "Est",
            alt: "pitchichi"
        }
    ]

    return (
        <>
            <Helmet>
                <title>Pitchichi | Guide des meilleures agences de communication à Paris et en France | Trouvez l’agence parfaite (branding, digital, publicité, social media, luxe)
                </title>
                <meta name="description" content="Le guide des meilleurs acteurs du marché de la communication répertoriés par les soins de Pitchichi l’expert en recommandation d’agences." />
                <link rel="pitchichi" href="https://www.pitchichi.com/guide-meilleures-agences-pub-com" />
            </Helmet>
            <Container fluid className="guide bg-section d-flex justify-content-center fade-in-fast">
                <div className="home-section-a vh-100 d-flex flex-column align-items-center justify-content-center text-primary hmax-1440">
                    <div className="title font-size-90 d-flex flex-column text-center line-height-09">
                        <span className="font-nhaas65 text-uppercase">
                            Guide
                        </span>
                        <span className="font-nhaas65 text-uppercase">
                            des agences
                        </span>
                        <span className="font-recoletalight text-uppercase">de communication</span>
                    </div>
                    <div className="font-size-33 font-nhaas45 text-center mt-3 mb-4 d-none d-sm-flex">
                        <span className="">Branding - Digital - Publicité - Luxe - Social media - Expérientiel</span>
                    </div>
                    <div className="font-size-33 font-nhaas45 d-flex flex-column text-center mt-3 mb-4 d-flex d-sm-none">
                        <span className="">Branding - Digital - Publicité - Luxe</span>
                        <span className="">Social media - Expérientiel</span>
                    </div>
                    <LinkContainer to="/wizard">
                        <Button variant="yellow" className="btn-pitchichi-primary">Trouver mon agence</Button>
                    </LinkContainer>
                </div>
            </Container>
            <Container fluid className="guide guide-section-2 bg-white justify-content-center flex-column fade-in-slow d-none d-sm-flex">
                <Row className="text-center line-height-1">
                    <Col className="font-size-35 font-nhaas45 align-items-center justify-content-center d-flex flex-column text-primary">
                        <div className="">
                            <span className="">
                                Pitchichi, l’expert de la recommandation d’agences, référence
                            </span>
                        </div>
                        <div className="">
                            <span className="">
                                les meilleurs acteurs du marché de la communication suivant sa seule appréciation.
                            </span>
                        </div>
                        <div className=" mt-5">
                            <LinkContainer to="/liste-meilleures-agences-communication-pub-branding-marque-France">
                                <span as="button" className="font-recoletasemibold clickable">Liste des agences - Recherche par nom</span>
                            </LinkContainer>
                        </div>


                    </Col>
                </Row>
            </Container>
            <Container fluid className="guide guide-section-2 bg-white justify-content-center flex-column fade-in-slow d-flex d-sm-none px-4">
                <Row className="text-center line-height-1">
                    <Col className="font-size-35 font-nhaas45 align-items-center justify-content-center d-flex flex-column text-primary">
                        <div className="">
                            <span className="">
                                Pitchichi, l’expert de la recommandation d’agences, référence les meilleurs acteurs du marché de la communication suivant sa seule appréciation.
                            </span>
                        </div>
                        <div className="">
                            <span className="">

                            </span>
                        </div>
                        <div className="">
                            <span className="">

                            </span>
                        </div>
                        <div className=" mt-5">
                            <LinkContainer to="/liste-meilleures-agences-communication-pub-branding-marque-France">
                                <span as="button" className="font-recoletasemibold clickable">Liste des agences - Recherche par nom</span>
                            </LinkContainer>
                        </div>


                    </Col>
                </Row>
            </Container>
            <Container fluid className="guide guide-section-search-by bg-white justify-content-center d-flex flex-column fade-in-slow">
                <Row className="text-center line-height-1">
                    <Col className="font-size-35 font-nhaas45 align-items-center justify-content-center d-flex flex-column text-primary mb-5">
                        <div className="">
                            <span className="font-size-55 font-recoletalight text-uppercase">
                                Recherche
                            </span>
                        </div>
                        <div className="">
                            <span className="font-size-55 font-recoletalight text-uppercase">
                                par{" "}
                            </span>
                            <span className="font-size-55 font-recoletasemibold text-uppercase">
                                catégorie
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row className="text-primary justify-content-center text-center m-auto width-max-guide-cat" >
                    {categories.map(cat => (
                        <LinkContainer to={`/liste-des-meilleures-agences-${cat.title}`}>
                            <div key={cat.title} className="col-6 col-md-4 col-lg-4 col-xl-2 mb-4">
                                <div className="card-categories d-flex flex-column align-items-center justify-content-center m-auto">
                                    <img src={cat.img} className="" alt={cat.alt} />
                                </div>
                                <span className="font-size-35 font-nhaas45" >{cat.title}</span>
                            </div>
                        </LinkContainer>

                    ))}
                </Row>

            </Container>



            <Container fluid className="guide guide-section-search-by bg-white justify-content-center d-flex flex-column fade-in-slow">
                <Row className="text-center line-height-1">
                    <Col className="font-size-35 font-nhaas45 align-items-center justify-content-center d-flex flex-column text-primary mb-5">
                        <div className="">
                            <span className="font-size-55 font-recoletalight text-uppercase">
                                RECHERCHE
                            </span>
                        </div>
                        <div className="">
                            <span className="font-size-55 font-recoletalight text-uppercase">
                                par{" "}
                            </span>
                            <span className="font-size-55 font-recoletasemibold text-uppercase">
                                VILLE {"&"} RÉGION
                            </span>
                        </div>
                    </Col>
                </Row>
                {/* <Row className="text-primary justify-content-center text-center m-auto" >
                    {categories.map(cat => (
                        <div className="col-6 col-md-4 col-lg-4 col-xl-2">
                            <div className="card-categories d-flex flex-column align-items-center justify-content-center m-auto">
                                <img src={cat.img} className="" alt={cat.alt} />
                            </div>
                            <span className="font-size-35 font-nhaas45">{cat.title}</span>
                        </div>
                    ))}
                </Row> */}
                <Row className="text-primary justify-content-center text-center m-auto width-max-guide-cat" >
                    {cities.map(citie => (
                        <LinkContainer to={`/liste-des-meilleures-agences-${citie.title}`}>
                            <div key={citie.title} className="col-6 mb-4 col-md-4 col-lg-4 col-xl-2">
                                <div className="card-cities d-flex flex-column align-items-center justify-content-center m-auto">
                                    <span className="font-size-35 font-nhaas45">{citie.title}</span>
                                </div>
                            </div>
                        </LinkContainer>
                    ))}
                </Row>
                <Row className="text-primary justify-content-center text-center m-auto width-max-guide-cat" >
                    {regions.map(region => (
                        <LinkContainer to={`/liste-des-meilleures-agences-${region.title}`}>
                            <div className="col-6 mb-4 col-md-4 col-lg-4 col-xl-2">
                                <div className="card-regions d-flex flex-column align-items-center justify-content-center m-auto">
                                    <span className="font-size-35 font-nhaas45">{region.title}</span>
                                </div>
                            </div>
                        </LinkContainer>
                    ))}
                </Row>

            </Container>


            <Container fluid className="guide guide-section-choice bg-white justify-content-center d-flex flex-column fade-in-slow ">
                <Row className="text-center line-height-1 ">
                    <Col className="align-items-center justify-content-center d-flex flex-column text-primary">
                        <div className="guide-section-logo">
                            <img src={card1} alt=""></img>
                        </div>
                        <div className="mt-5">
                            <span className="font-size-35 font-nhaas45">
                                Difficile de faire son choix ?
                            </span>
                        </div>
                        <div className="mb-5">
                            <span className="font-size-35 font-nhaas45">
                                Pitchichi vous propose sa sélection d’agences pour votre projet.
                            </span>
                        </div>

                        <LinkContainer to="/wizard">
                            <Button variant="yellow" className="btn-pitchichi-primary">Recevoir ma sélection gratuite</Button>
                        </LinkContainer>
                    </Col>
                </Row>
            </Container>


        </>
    );
}