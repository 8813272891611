import React from 'react';
import "../../../App.scss";
import "./SectionF.scss";
import { LinkContainer } from 'react-router-bootstrap'




import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";


import section3card1 from "../../../assets/illustrations/section3/section3-card1.png.webp";
import section3card2 from "../../../assets/illustrations/section3/section3-card2.png.webp";
import section3card3 from "../../../assets/illustrations/section3/section3-card3.png.webp";
import section3card4 from "../../../assets/illustrations/section3/section3-card4.png.webp";



export default function SectionF() {
    return (
        <>
            <Container fluid className="home-section-f bg-primary d-flex flex-column align-items-center justify-content-center text-secondary ">
                <div className="d-flex flex-column align-items-center justify-content-center ">
                    <div className="line-height-09 font-size-55 d-flex flex-column text-center">
                        <span className="font-nhaas65 text-uppercase">
                            comment
                        </span>
                        <span className="font-recoletalight text-uppercase">ça marche ?</span>
                        <span className="font-recoletaregular font-size-18 mt-3 text-uppercase">100% gratuit pour l’annonceur</span>
                    </div>
                </div>
                <div className="card-section">

                    <Row className="mt-2 mt-md-5">
                        <Col xs={6} md={4} className="text-right section6-card1"><img src={section3card1} width="100%" height="auto" alt="pitchichi" /></Col>
                        <Col xs={6} md={8} className="pl-0 pl-md-3">
                            <div className="d-flex flex-column">
                                <span className="font-nhaas65 font-size-75 line-height-1 mb-1 text-yellow">01</span>
                                <div className="d-flex flex-column font-nhaas45 line-height-1 font-size-35 text-secondary mb-1 mb-md-3">
                                    <span className="">Remplissez le brief</span>
                                </div>
                                <div className="d-flex flex-column font-recoletaregular line-height-1 font-size-22 text-secondary">
                                    <span>En quelques clics,</span>
                                    <span>répondez à nos questions</span>
                                    <span>pour décrire votre projet.</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-5 mt-md-0 flex-row-reverse flex-md-row">
                        <Col xs={6} md={10} className="text-left text-md-right section6-card2"><img width="100%" height="auto" src={section3card2} alt="pitchichi" /></Col>
                        <Col xs={6} md={2} className="pr-0 pr-md-3">
                            <div className="d-none d-sm-flex flex-column text-right text-md-left">
                                <span className="font-nhaas65 font-size-75  mb-1 text-yellow">02</span>
                                <div className="d-flex flex-column font-nhaas45 line-height-1 font-size-35 text-secondary mb-1 mb-md-3">
                                    <span className="">Échangez avec</span>
                                    <span className="">nos experts</span>
                                </div>
                                <div className="d-flex flex-column font-recoletaregular line-height-1 font-size-22 text-secondary  text-wrap text-break">
                                <span>Par téléphone ou en visio, précisez vos besoins et la méthode de consultation.</span>
                                </div>
                            </div>
                            <div className="d-flex d-sm-none flex-column text-right text-md-left">
                                <span className="font-nhaas65 font-size-75  mb-1 text-yellow">02</span>
                                <div className="d-flex flex-column font-nhaas45 line-height-1 font-size-35 text-secondary mb-1 mb-md-3">
                                    <span className="">Échangez avec</span>
                                    <span className="">nos experts</span>
                                </div>
                                <div className="d-flex flex-column font-recoletaregular line-height-1 font-size-22 text-secondary text-wrap text-break">
                                    <span>Par téléphone ou en visio, précisez vos besoins et la méthode de consultation.</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-5 mt-md-0">
                        <Col xs={6} md={2} className="text-right section6-card3 mt-n3 mt-md-n5"><img width="100%" height="auto" src={section3card3} alt="pitchichi" /></Col>
                        <Col xs={6} md={8} className="pl-0 pl-md-3">
                            <div className="d-none d-sm-flex flex-column">
                                <span className="font-nhaas65 font-size-75 line-height-1 mb-1 text-yellow">03</span>
                                <div className="d-flex flex-column font-nhaas45 line-height-1 font-size-35 text-secondary mb-1 mb-md-3">
                                    <span className="">Recevez</span>
                                    <span className="">notre sélection</span>
                                </div>
                                <div className="d-flex flex-column font-recoletaregular line-height-1 font-size-22 text-secondary">
                                    <span>Découvrez 5 agences* parfaitement</span>
                                    <span>adaptées et discutez-en</span>
                                    <span>avec nos experts.</span>
                                </div>
                            </div>
                            <div className="d-flex d-sm-none flex-column">
                                <span className="font-nhaas65 font-size-75 line-height-1 mb-1 text-yellow">03</span>
                                <div className="d-flex flex-column font-nhaas45 line-height-1 font-size-35 text-secondary mb-1 mb-md-3">
                                    <span className="">Recevez</span>
                                    <span className="">notre sélection</span>
                                </div>
                                <div className="d-flex flex-column font-recoletaregular line-height-1 font-size-22 text-secondary text-wrap">
                                    <span>Découvrez 5 agences* parfaitement adaptées et discutez-en avec nos experts.</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-5 flex-row-reverse flex-md-row">
                        <Col xs={6} md={6} className="text-left text-md-right section6-card4"><img width="100%" height="auto" src={section3card4} alt="pitchichi" /></Col>
                        <Col xs={6} md={6} className="pr-0 pr-md-3">
                            <div className="d-flex flex-column text-right text-md-left">
                                <span className="font-nhaas65 font-size-75 line-height-1 mb-1 text-yellow">04</span>
                                <div className="d-flex flex-column font-nhaas45 line-height-1 font-size-35 text-secondary mb-1 mb-md-3">
                                    <span className="">Choisissez</span>
                                    <span className="">votre agence</span>
                                </div>
                                <div className="d-flex flex-column font-recoletaregular line-height-1 font-size-22 text-secondary">
                                    <span>Consultez les agences suivant</span>
                                    <span>notre méthode et choisissez</span>
                                    <span>votre « coup de coeur ».</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="m-auto">
                        <Col className="text-center">
                            <LinkContainer to="/wizard">
                                <Button variant="yellow" className="pitchichi btn-pitchichi-primary">Trouver mon agence</Button>
                            </LinkContainer>
                        </Col>
                    </Row>
                    <Row className="m-auto">
                        <Col className="text-center font-nhaas55 font-size-15">
                            <div>Gratuit pour les annonceurs</div>
                        </Col>
                    </Row>
                </div>
                <div className="align-self-end text-secondary mt-4">
                    <div className="d-flex flex-column text-right line-height-1">
                        <span className="font-nhaas45 font-size-12">* : 5 agences + ou - 1</span>
                    </div>
                </div>

            </Container>

        </>

    )
}