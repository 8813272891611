import "../../../App.scss";
import "./SectionD.scss";

import React from 'react';
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import section2card1 from "../../../assets/illustrations/section2/section2-card1.png.webp";
import section2card2 from "../../../assets/illustrations/section2/section2-card2.png.webp";
import section2card3 from "../../../assets/illustrations/section2/section2-card3.png.webp";


export default function SectionD() {
    return (
        <>
            <Container fluid className="home-section-d bg-blue d-flex flex-column align-items-center justify-content-center line-height-1">
                <div className="d-flex flex-column align-items-center justify-content-center text-primary">
                    <div className="line-height-09 font-size-55 d-flex flex-column text-center">
                        <span className="font-recoletalight text-uppercase">pourquoi</span>
                        <span className="font-recoletalight text-uppercase">demander{" "}<span className="font-nhaas65 text-uppercase">
                            conseil
                        </span></span>
                        <span className="font-recoletalight text-uppercase">à{" "}<span className="font-nhaas65 text-uppercase">
                            pitchichi{" "}<span className="font-recoletalight">?</span>
                        </span></span>
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row card-section align-items-center justify-content-between">
                    <Card className="card-section3 border-0 bg-blue text-left text-md-center align-items-center justify-content-between text-primary flex-row flex-md-column">
                        <Card.Img variant="top" src={section2card1} className="card-left m-auto" />
                        <Card.Body className="d-flex flex-column mt-0 mt-md-5">
                            <Card.Title className="align-self-start align-self-md-center">
                                <span className="font-size-24 font-recoletasemibold line-height-1 ">Connaissance parfaite</span>

                            </Card.Title>

                            <Card.Text className="line-height align-self-start align-self-md-center">
                                <div>
                                    <span className="font-nhaas55 font-size-18">Parce que le marché</span>
                                </div>
                                <div>
                                    <span className="font-nhaas55 font-size-18">évolue vite, nous l’observons</span>
                                </div>
                                <div>
                                    <span className="font-nhaas55 font-size-18">en permanence</span>
                                </div>

                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="card-section3 border-0 bg-blue text-left text-md-center align-items-center justify-content-between text-primary flex-row flex-md-column my-5 my-md-0">
                        <Card.Img variant="top" src={section2card2} className="card-middle mt-3 m-auto" />
                        <Card.Body className="d-flex flex-column mt-0 mt-md-5">
                            <Card.Title className="align-self-start align-self-md-center">
                                <span className="font-size-24 font-recoletasemibold">Sélection à la main</span>

                            </Card.Title>

                            <Card.Text className="line-height align-self-start align-self-md-center">
                                <div>
                                    <span className="font-nhaas55 font-size-18">Pour matcher parfaitement,</span>
                                </div>
                                <div>
                                    <span className="font-nhaas55 font-size-18">nos sélections sont finalisées</span>
                                </div>
                                <div>
                                    <span className="font-nhaas55 font-size-18">par nos experts</span>
                                </div>

                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="card-section3 border-0 bg-blue text-left text-md-center align-items-center justify-content-between text-primary flex-row flex-md-column">
                        <Card.Img variant="top" src={section2card3} className="card-right m-auto" />
                        <Card.Body className="d-flex flex-column mt-0 mt-md-4">
                            <Card.Text className="align-self-start align-self-md-center line-height-1">
                                <div>
                                    <span className="font-size-24 font-recoletasemibold ">Gratuit et</span>
                                </div>
                                <div>
                                    <span className="font-size-24 font-recoletasemibold ">sans obligation</span>
                                </div>

                            </Card.Text>

                            <Card.Text className="line-height align-self-start align-self-md-center">
                                <div>
                                    <span className="font-nhaas55 font-size-18">Pour aider le plus grand nombre</span>
                                </div>
                                <div>
                                    <span className="font-nhaas55 font-size-18">d’annonceurs, nous sommes</span>
                                </div>
                                <div>
                                    <span className="font-nhaas55 font-size-18">gratuits*.</span>
                                </div>

                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className="align-self-end text-primary mt-3">
                    <div className="d-flex flex-column text-right line-height-1">
                        <span className="font-nhaas45 font-size-12">* : nous sommes rémunérés</span>
                        <span className="font-nhaas45 font-size-12">par l’agence qui remporte votre projet</span>
                    </div>
                </div>
            </Container>


        </>
    )
}