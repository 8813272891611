import "../../App.scss";
import React from 'react';
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Wizard.scss";

export default function WizardCompleted() {
	return (
		<>
			<Helmet>
				<title>Pitchichi | Trouvez l’agence parfaite | Plateforme de recommandation d’agences de communication | Site recherche et conseil d’agences
				</title>
				<meta name="description" content="Trouvez votre agence de communication gratuitement et en quelques clics grâce à notre questionnaire." />
				<link rel="pitchichi" href="https://www.pitchichi.com/wizard" />
			</Helmet>
			<Container fluid className="wizard bg-secondary justify-content-center d-flex fade-in-slow">
				<Row className="wizard-row justify-content-center p-0">
					<Col className="align-items-center justify-content-center d-flex  text-primary p-0">
						<div className="sv_completed_page">
							<div className="illustration"></div>
							<span className="completed-title">C'EST BON,</span>
							<span className="completed-title-2">on a tout ce qu’il nous faut !</span>
							<span className="completed-title-3">Nous regardons tout ça et nous vous rappelons vite pour en discuter de vive voix.</span>
						</div>
					</Col>
				</Row>
			</Container>
		</>

	);
}