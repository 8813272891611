import "../../App.scss";
import React, { useState, useEffect } from "react";
import axios from 'axios'
import { API_URL } from "../../util/Constant";
import "./Cases.scss";
import ReactPlayer from 'react-player'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

export default function Cases(props) {
    const [customerCase, setCustomerCase] = useState('')
    const getCustomerCase = () => {
        axios.get(API_URL + "/customer-cases/" + props.match.params.slug + "/")
            .then((response) => {
                setCustomerCase(response.data)
            })
            .catch(error => console.error(error))
    }
    useEffect(() => {
        getCustomerCase();
    }, []);

    return (
        <>
            <Container fluid className="bg-white d-flex flex-column justify-content-center fade-in-slow cases-main-container text-primary wmax-2160">
                <Row className="mt-md-5 mb-md-5 px-md-4 mb-3">
                    <Col className="d-flex justify-content-center">
                        <div>
                            <img src={customerCase?.banner?.file} alt="pitchichi" className="case-img1" />
                        </div>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col sm={8} className="d-flex justify-content-center flex-column align-items-center line-height-1">
                        <div className="font-size-55 font-nhaas65 text-uppercase">{customerCase?.customer}</div>
                        <div className="font-size-35 font-recoletaregular">{customerCase?.name}</div>
                        <div className="font-size-35 font-nhaas45 text-center mt-md-5 mt-3">
                            {customerCase?.description}
                        </div>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center my-md-5 my-3 font-size-20 font-nhaas65">
                    <Col sm={8} className="d-flex justify-content-center flex-column align-items-center line-height-12">
                    {customerCase?.unreferenced_agency_name && <div className="text-center">
                            Projet réalisé par : {customerCase?.unreferenced_agency_name}
                        </div>}
                        <div className="font-size-20 font-nhaas65">{customerCase?.year}</div>
                    </Col>
                </Row>
                <Row className="px-md-4">
                    {customerCase?.images?.map((image) => (
                        <Col className="d-flex justify-content-center p-md-3 p-3 ">
                            <div>
                                <img src={image?.file} alt="pitchichi" className="case-img1" />
                            </div>
                        </Col>
                    ))}
                </Row>
                {customerCase?.video_urls?.map((video) => (
                    <Row className="px-md-4">
                        <Col className="d-flex justify-content-center video rounded p-md-3 p-3">
                            <ReactPlayer url={video} width="100%" height="100%" />
                        </Col>
                    </Row>
                ))}
            </Container>
        </>
    );
}