import React from "react";
import "../../App.scss";
import "./SectionC.scss";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Container from "react-bootstrap/Container";
import choice2 from "./../../assets/illustrations/firm/Asset2.svg";
import choice3 from "./../../assets/illustrations/firm/Asset3.svg";
import choice4 from "./../../assets/illustrations/firm/Asset4.svg";
import choice5 from "./../../assets/illustrations/firm/Asset5.svg";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";


export default function SectionC(props) {
    
    return (
        <Container
            fluid
            className="bg-primary text-primary py-5 fade-in-slow d-sm-none"
        >
            <Row className="d-flex flex-column text-center justify-content-center align-items-center text-secondary mb-3 mb-md-5 line-height-1">
                <div className="">
                    <span className="font-size-55 font-nhaas65 text-uppercase">
                        pourquoi{" "}
                    </span>
                    <span className="font-size-55 font-recoletalight text-uppercase">
                        vous
                    </span>

                </div>
                <div className="">
                    <span className="font-size-55 font-recoletalight text-uppercase">
                        faire accompagner
                    </span>
                </div>
                <div className="">
                    <span className="font-size-55 font-recoletalight text-uppercase">
                        par pitchichi?
                    </span>
                </div>
            </Row>
            <Carousel showStatus={false} showArrows={false} showThumbs={false} infiniteLoop={true} className="carouselfirm1">
                <Row className="d-flex justify-content-center align-items-center text-center m-0 ">
                    <Col xs={12} className="d-flex flex-column justify-content-center bg-blue border-radius-24 py-4 px-3" style={{height: "300px"}}>
                        <Row className="justify-content-center ">
                            <img src={choice2} alt="pitchichi" style={{maxHeight: "none"}}/>
                        </Row>
                        <Row className="justify-content-center m-3">
                            <div className="font-size-24 font-recoletasemibold">Sa connaissance</div>
                            <div className="font-size-24 font-recoletasemibold">parfaite du marché</div>
                        </Row>
                        <Row className="justify-content-center  px-3">
                            <div className="font-size-18 font-nhaas55">Le monde des agences est en perpétuelle mutation, nous observons quotiennement ses nouveaux acteurs et ses nouvelles offres. </div>
                        </Row>
                    </Col>
                </Row>



                <Row className="d-flex justify-content-center align-items-center text-center m-0  ">
                    <Col xs={12} className="d-flex flex-column justify-content-center  border-radius-24 bg-blue py-4 px-3" style={{height: "300px"}}>
                        <Row className="justify-content-center">
                            <img src={choice3} alt="pitchichi" style={{maxHeight: "none"}}/>
                        </Row>
                        <Row className="justify-content-center m-3">
                            <div className="font-size-24 font-recoletasemibold">Son profil unique</div>
                        </Row>
                        <Row className="justify-content-center  px-3">
                            <div className="font-size-18 font-nhaas55">Le bon choix sera toujours le vôtre mais échanger avec un expert en création extérieur aux agences vous offrira un éclairage supplémentaire.</div>
                        </Row>
                    </Col>
                </Row>

                <Row className="d-flex justify-content-center align-items-center text-center  m-0 ">
                    <Col xs={12} className="d-flex flex-column justify-content-center  border-radius-24 bg-blue py-4 px-3" style={{height: "300px"}}>
                        <Row className="justify-content-center">
                            <img src={choice4} alt="pitchichi" style={{maxHeight: "none"}}/>
                        </Row>
                        <Row className="justify-content-center m-3">
                            <div className="font-size-24 font-recoletasemibold">Son ambition créative</div>
                        </Row>
                        <Row className="justify-content-center  px-3">
                            <div className="font-size-18 font-nhaas55">Lancer un appel d’offres avec Pitchichi est une plus-value remarquée par les agences, une caution de votre ambition créative.</div>
                        </Row>
                    </Col>
                </Row>

                <Row className="d-flex justify-content-center align-items-center text-center  m-0 ">
                    <Col xs={12} className="d-flex flex-column justify-content-center  border-radius-24 bg-blue py-4 px-3" style={{height: "300px"}}>
                        <Row className="justify-content-center">
                            <img src={choice5} alt="pitchichi" style={{maxHeight: "none"}}/>
                        </Row>
                        <Row className="justify-content-center m-3">
                            <div className="font-size-24 font-recoletasemibold">Sa méthode</div>
                            <div className="font-size-24 font-recoletasemibold">« La Création d’Abord ! »</div>

                        </Row>
                        <Row className="justify-content-center px-3">
                            <div className="font-size-18 font-nhaas55">Pour mettre l’annonceur dans la peau du consommateur, Pitchichi demande aux agences de commencer leur présentation directement par la création. </div>
                        </Row>
                    </Col>
                </Row>

            </Carousel>

        </Container >
    )
}